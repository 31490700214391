import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }
   ngAfterViewInit(): void {
     //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
     //Add 'implements AfterViewInit' to the class.
     if(window.localStorage){
      if(! localStorage.getItem('firstLoad')){
        localStorage['firstLoad']=true;
        // console.log(localStorage['firstLoad'])
        window.location.reload();
        // console.log(localStorage['firstLoad'])

        
      }
      else
      localStorage.removeItem('firstLoad');
      // console.log(localStorage['firstLoad'])

    }
   }

}
