import { Component, OnInit } from '@angular/core';
declare var hbspt: any // put this at the top
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  constructor() { 
    
  }

  ngOnInit(): void {
    hbspt.forms.create({
      portalId: "8281117",
      formId: "ed8f184e-95dd-4fba-86e9-9d73c9e00dca",
      target: "#hubspotForm"
    });
  }

}
