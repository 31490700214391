<header class="page header text-contrast overlay alpha-8 image-background cover gradient gradient-purple-blue" style="background-image: url('img/bg/waves.jpg')">
    <div class="divider-shape">
        <!-- waves divider --> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" class="shape-waves" style="left: 0; transform: rotate3d(0,1,0,180deg);">
            <path class="shape-fill shape-fill-contrast" 
            d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z" />
        </svg>
    </div>
    <div class="container" >
        <div class="row">
            <div class="col-md-6">
                <h1 class="regular display-4 text-contrast mb-4">Contact Us</h1>
                <p class="lead text-contrast"> 
                    New Peripherals Technology Pvt. Ltd,  an IT & Electronics Consumables Company was incorporated in the year 2015 with its flagship Brand “ NPTECH “.
                    
                </p>
            </div>
        </div>
    </div>
</header><!-- ./Overview - Floating boxes -->



<div class="contact-area mtb-60px">
    <div class="container">
        <div class="contact-map mb-10">
            <div id="map" style=" text-align:center;">
                <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.7024526022424!2d81.63676751444885!3d21.243644485784625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13fe028c897a1f93%3A0x8a1a7c4151271a70!2sNEW%20PERIPHERLS%20TECHNOLOGY!5e0!3m2!1sen!2sin!4v1596117344384!5m2!1sen!2sin"
                width="90%" height="400" frameborder="0" style="border: 0px; pointer-events: none;" allowfullscreen=""></iframe> -->
                <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=NP%20TECH%20Raipur&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.embedgooglemap.net">google maps iframe</a></div><style>.mapouter{position:relative;text-align:right;height:400px;width:100%;}.gmap_canvas {overflow:hidden;background:none!important;height:400px;width:100%;}</style></div>
          
            </div>
        </div>
        <div class="custom-row-2">
            
            <div class="col-lg-7 col-md-7 mb-lm-30px">
                <div class="contact-info-wrap">
                    <div class=" row">
                        <div class=" col-md-6">
                            <div class="single-contact-info">
                                
                                <p>
                                 <span  class="office-head"> HEAD OFFICE: </span> <br> <br>
                                NEW PERIPHERALS TECHNOLOGY PVT LTD. <br>
                                1st FLOOR,ABID MANSION, <br>
                                OPP MILENIUM PLAZA,BASTAL ROAD <br>
                                RAIPUR (C.G.)492001 <br>
                                CONTACT DETAIL: 0771 – 4028408 / 4026189 <br>
                                E MAIL : nptech@outlook.in 
                            </p>
                            </div>
                        </div>
                        <div class=" col-md-6">
                            <div class="single-contact-info">
                                <p>
                                    <span  class="office-head"> DELHI BRANCH OFFICE: </span> <br> <br>
                                
                                    SHRI LALJI ENTERPRISES <br>
                                    ED-53B,PITAMPURA,MADHUBAN CHOWK <br>
                                    DELHI-110034 <br>
                                    CONTACT DETAIL: 9315837949 / 9205999169 <br>
                                    E MAIL : shrilaljienterprises@yahoo.com 
                               </p>
                            </div>
                        </div>
                       
                    </div>
                    <div class=" row mt-md-5 pt-md-2">
                        <div class=" col-md-4">
                            <div class="single-contact-info">
                                <p>
                                    <span  class="office-head"> ORISSA BRANCH OFFICE: </span> <br> <br> <br>
                                    NEW PERIPHERALS TECHNOLOGY <br>
                                    PLOT NO-251,SAHID NAGAR <br>
                                    BHUBNESWAR (ODISHA) 751007 <br>
                                    CONTACT DETAIL: 0674 – 2546688 <br>
                                    E MAIL : nptech.bbsr@gmail.com <br>
                               </p>
                            </div>
                        </div>
                        <div class=" col-md-4">
                            <div class="single-contact-info">
                                
        
                                <p>
                                    <span  class="office-head"> MAHARASTRA BRANCH OFFICE: </span> <br> <br>
                                    SHRI LALJI COMPUTERS <br>
                                    PLOT 34, ABHYANKAR ROAD, ABOVE TRYCON COURIER <br>
                                    NEAR RAM KRISHNA MATH,  DHANTOLI, <br>
                                    NAGPUR – 440012 <br>
                                    CONTACT DETAIL: 8602902510, 7289927026, <br>
                                    E MAIL : nptech.nagpur@outlook.com
                               </p>
                            </div>
                        </div>
                        <div class=" col-md-4">
                            <div class="single-contact-info">
                                <p>
                                    <span  class="office-head"> RAJASTHAN BRANCH OFFICE: </span> <br> <br>
                                
                                    NP TECHNOLOGY <br>
                                    F-19,SHANKAR MARG,KANTI CHANDRA ROAD <br>
                                    BANI PARK,NEAR D CIRCLE PARK <br> 
                                    JAIPUR – 302016 <br>
                                    CONTACT DETAIL 9009989279 / 9352739557 <br>
                                    E MAIL : sales.nptech@outlook.com <br>
                               </p>
                            </div>
                        </div>
                    </div>
                   

                    <!-- <div class="contact-social">
                        <h3>Follow Us</h3>
                        <div class="social-info">
                            <ul>
                                <li>
                                    <a href="#"><i class="ion-social-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="ion-social-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="ion-social-youtube"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="ion-social-google"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="ion-social-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
            
            <div class="col-lg-5 col-md-5">
                <div class="contact-form">
                    <div class="contact-title mb-30">
                        <h2>Get In Touch</h2>
                    </div>
                    <form class="contact-form-style" id="contact-form" action="#" method="post"> 
                        <!-- <div class="row">
                            <div class="col-lg-6">
                                <input name="name" placeholder="Name*" type="text" />
                            </div>
                            <div class="col-lg-6">
                                <input name="email" placeholder="Email*" type="email" />
                            </div>
                            <div class="col-lg-12">
                                <input name="subject" placeholder="Subject*" type="text" />
                            </div>
                            <div class="col-lg-12">
                                <textarea name="message" placeholder="Your Message*"></textarea>
                                <button class="submit" type="submit">SEND</button>
                            </div>
                        </div> -->
                        <!-- <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
                        <script>
                                hbspt.forms.create({
                                    portalId: "8281117",
                                    formId: "ed8f184e-95dd-4fba-86e9-9d73c9e00dca"
                                });
                        </script> -->
                        <div id="hubspotForm"> </div>
                    </form> 
                   
                    <p class="form-messege"></p>
                </div>
            </div>
        </div>
    </div>
</div>
