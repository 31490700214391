<main class="position-relative overflow-hidden">
    <!-- ./Page header -->
    <header class="page header text-contrast overlay alpha-8 image-background cover gradient gradient-purple-blue" style="background-image: url('img/bg/waves.jpg')">
        <div class="divider-shape">
            <!-- waves divider --> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" class="shape-waves" style="left: 0; transform: rotate3d(0,1,0,180deg);">
                <path class="shape-fill shape-fill-contrast" 
                d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z" />
            </svg>
        </div>
        <div class="container" >
            <div class="row">
                <div class="col-md-6">
                    <h1 class="regular display-4 text-contrast mb-4">E-Waste Management</h1>
                    <p class="lead text-contrast"> 
                        The company has tied -up with Hulladek Recycling Pvt Ltd,  an authorised PRO  for safe disposal of E-Waste collected from all over India. </p>
                </div>
            </div>
        </div>
    </header><!-- ./Overview - Floating boxes -->
    <section class="section overview">
        <div class="container">
            <div class="row align-items-center gap-y">
                <div class="col mr-auto text-center text-md-left">
                    <div class="section-heading">
                        <p class="badge badge-success bold">Recycling by NPTECH</p>
                        <h2>
                            Introduction 
                        </h2>
                        <div class="section-heading">
                           <h3> Contributing towards sustainability</h3>
                        </div>
                        <p class="lead text-secondary">
                            Electronic waste, or e-waste, is a term for electronic products that have become unwanted, non-working or obsolete, and have essentially reached the end of their useful life. As per E-waste Rule 2016, the E waste defined as electrical and electronic equipment, whole or in part discarded as waste by the consumer or bulk consumer as well as rejects from manufacturing, refurbishment, and repair processes. E-waste contains many valuable, recoverable materials such as aluminum, copper, gold, silver, plastics, and ferrous metals. In order to conserve natural resources and the energy needed to produce new electronic equipment from virgin resources, electronic equipment can be refurbished, reused, and recycled instead of being land.
                        </p>
                        <p class="lead text-secondary">
                            E-waste also contains toxic and hazardous materials including mercury, lead, cadmium, beryllium, chromium, and chemical flame retardants, which have the potential to leach into our soil and water. 
                        </p>
                        <h3>
                            Recycling of E-waste:

                        </h3>
                        <p class="lead text-secondary">
                            The company has tied -up with <a href="https://hulladek.in/"> Hulladek Recycling Pvt Ltd </a> , an authorised PRO  for safe disposal of E-Waste collected from all over India. As a part of agreement, Hulladek Recycling Pvt. Ltd. will collect all our e-waste generated from PAN India and dispose the same. Our PRO has signed an agreement with Om Logistics Ltd. for their logistics service who will help with the collection and channelization of e-waste pan India. Their collection centres have been mentioned below. The material collected by them will be channelized to our PRO’s recyclers/dismantlers, Eco-E waste Recyclers India Pvt. Ltd., Eco Birdd Recycling Company Pvt. Ltd., Sani Clean Pvt. Ltd., Green Waves Environmental Solutions & Thapar Disposal Industry having a total capacity of 3165 MT per annum for recycling/dismantling. Customer can reach our office, call, or email us. The representatives will explain about process of disposal and make them aware about nearest drop point available to drop the e-waste. If any customer wants to handover the material from their doorsteps, we can send our logistics team to collect the items and channelize the same to our e-waste partner plant for final processing.    
                        </p>
                        <h3>
                            BRIEF ON GENERAL SCHEMES OF COLLECTION
                        
                        </h3>
                        <h4>
                            Buyback
                        </h4>
                        <p class="lead text-secondary">
                            We plan to collect the E-waste from the bulk consumers, individual consumers and resident associations with the help of the PRO. These individuals and organisations will be intimated through emails about the whole process. We also plan to install collection bins at various residential associations and organisations so that consumers can dispose the e-waste in such bins at their own convenience over a period of time. They can also call up on our toll-free no. and we will coordinate with them in buying back the materials. We will provide them with an incentive for the materials they dispose with us.
                         
                        </p>
                        <h4>
                            Exchange and Coupons
                        </h4>
                        <p class="lead text-secondary">
                            Under the special collection scheme, we also plan to issue 1% Discount Voucher to the customers who will return the EOL products to us. This exchange facility will be offered to every customer. The discount amount being Rs. 1000 (maximum) depending on the quality of the old product. Customers can redeem this voucher on their future purchases from our company. 
                        </p>
                        <h4>
                            APPEAL TO OUR CUSTOMERS:
                        </h4> 
                        <p class="lead text-secondary">
                            In the event of disposing off your products which have been imported by us, you may kindly call us at our Toll-Free Number 1800-212-7880, from 10am to 7pm (All Days Except Sunday) for assistance. You can also send the material to the below mentioned address (collection centres) of our logistics partner, Om logistics Ltd.
                        </p> 
                
                    </div>
                </div>
                
            </div>
            <div class="row align-items-center gap-y location">


               
                <div class="col-lg-6">
                    <div class="row gap-y">
                        <div class="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"> <i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i>
                                <p class="bold mb-0">BIHAR</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Gaya
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    KRISHNA PURI COLONY KANDINAWADA NEAR MAHABODHI ITI INSTITUTE, GAYA PATNA ROAD, PO- BITHOSARIF, PS- CHANDAUTI-823001 
                                    <br>
                                    Contact Person -
                                    <br> 
                                    Mr. KARUNAKAR PANDEY  
                                    <br>
                                    7091598664 
                                    <br> 
                                    gaya@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mr-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i>
                                <p class="bold mb-0">CHHATTISGARH</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Bilaspur
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    2/27 VYAPAR VIHAR OLD RTO COMPOUND BILASPUR-495001
                                    <br>
                                    Contact Person -
                                    <br> 
                                    MR. NEPAL RAM 
                                    <br>
                                    8370005229 
                                    <br> 
                                    bilaspur@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 text-contrast bg-info-gradient shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">
                                    Telangana
                                </p>
                        
                                <p class="op-7 small">
                                    City/Town - Cherlapally
                                    
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    H.NO.-2-10-497 EC NAGAR PHASE -3 CHERLAPALLY PIN-500051 <br>
                                    Contact Person -
                                    <br> 
                                    MR. BHARATH CHOUDARY
                                    <br>
                                    8886332160 
                                    <br> 
                                    Hyd_cply@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mt-n6 mr-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">ANDHRA PRADESH 
                                </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Chittoor
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    PLOT NO 677-2R2 672-3R 672-LC AND 672-2E, SOMANDEPALLI VILLAGE MANDAL ANANTPUR-515122 <br>
                                    Contact Person -
                                    <br> 
                                    MR. ASHOK YADAV
                                    <br>
                                    8050070235 
                                    <br> 
                                    bm_anantpur@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row gap-y">
                        <div class="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0"> KERALA</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Cochin
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    BUILDING NUMBERS & NO.XV/180 & XV/180A KARUMALLOOR PANCHAYAT U.C COLLEGE ALUVA (P.O) ERNAKULAM KERALA-683102
                                    <br>
                                    Contact Person -
                                    <br> 
                                    MR. GYANENDRA KUMAR <br>
                                    034025581, 
7034025583
                                    <br> 
                                    bm_cochin@omlogistics.co.in
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mr-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">GOA</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Goa
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    PLOT NO. S141 PHASE 3B NEAR RAVI CAFE NEAR LOKMAT PRINTING PRESS VERNA INDUSTRIAL ESTATE VERNA GOA 403722<br>
                                    Contact Person -
                                    <br> 
                                    MR. ARVIND KUMAR <br>
                                    9881777107, 
9881777108 
                                    <br> 
                                    bm_goa@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 ml-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">MAHARASHTRA </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Ahmednagar
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    PLOT NO 75 BEHIND BARODA BANK MIDC AHMEDNAGAR TAL & DIST.AHMEDNAGAR PINCODE-611411 <br>
                                    Contact Person -
                                    <br> 
                                    MR. HARIKESH SINGH  
                                    <br>
                                    9881777067, 
9881777068 
                                    <br> 
                                    anagar@omlogistics.co.in 
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mt-n6 mr-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">GUJARAT</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Ahmedabad
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    SHOP NO-3 DIVYA COMPLEX OPP GROUND WATER TANK NR. TEL. EXCHANGE BOMBAY CONDUCTOR ROAD PH-1 GIDC VATWA AHMEDABAD PIN NO 382443<br>
                                    Contact Person -
                                    <br> 
                                    MR. RAMPAL SINGH  
                                    <br>
                                    9714502010 
                                    <br> 
                                    mum_rampal@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row gap-y">
                        <div class="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"> <i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i>
                                <p class="bold mb-0">MADHYA PRADESH </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Bhopal
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    501 VILLAGE KHATAMBA NEAR JOHNDEERE PLANT DEWAS BHOPAL HIGHWAY DEWAS-455001<br>
                                    Contact Person -
                                    <br> 
                                    MR. SUNIL YADAV
                                    <br>
                                    8370005222, 
8370005216 
                                    <br> 
                                    bm_dewas@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mr-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i>
                                <p class="bold mb-0">DAMAN AND DIU </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Daman
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    SHOP NO. 02 RAGHUBIR COMPLEX NEAR DAMAN WEIGH BRIDGE VAPI DAMAN MAIN ROAD, Daman-396210 <br>
                                    Contact Person -
                                    <br> 
                                    MR. VIKAS JANGRA
                                    <br>
                                    9714502036, 
9714501585 
                                    <br> 
                                    bm_daman@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 text-contrast bg-info-gradient shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">DADRA AND NAGAR HAVELI </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Vapi
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    OPPOSITE SVIL NEAR MOHAN FATAK KARAM BELLY VILLAGE VAPI-396195 <br>
                                    Contact Person -
                                    <br> 
                                    MR. AJAY SHARMA 
                                    <br>
                                    9714501580, 
9737296243 
                                    <br> 
                                    bm_vapi@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mt-n6 mr-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0"> RAJASTHAN</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Bhiwadi
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    G-273 RIICO INDS AREA G- 273 PHASE - 1 NEAR SBI BANK NEELAM CHOWK BHIWADI-301019 <br>
                                    Contact Person -
                                    <br> 
                                    MR. UGRASEN YADAV
                                    <br>
                                    9649999023, 
9649999031
                                    <br> 
                                    bhiwadi@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row gap-y">
                        <div class="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">UTTAR PRADESH</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Allahabad
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    13F/13E/13D TRASPORT NAGER NEAR BAJRANG CHOURAHA MUNDERA ALLAHABAD-211011 <br>
                                    Contact Person -
                                    <br> 
                                    MR. DINESH KAUSHIK 
                                    <br>
                                    8392921891 
                                    <br> 
                                    allahabad@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mr-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">PUNJAB</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Amritsar
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    E-1 INDUSTRIAL AREA NEAR BSNL EXCHANGE JALANDHAR PIN CODE-144004 STATE PUNJAB E-1 INDUSTRIAL AREA NEAR BSNL EXCHANGE JALANDHAR PIN CODE-144004 <br>
                                    Contact Person -
                                    <br> 
                                    MR. BIJENDER SANGWAN
                                    <br>
                                    8558899516 
                                    <br> 
                                    jalandhar@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 ml-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">CHANDIGARH </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Chandigarh
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    PLOT NO-186 IND AREA PHASE-2 RAMDARBAR CHANDIGARH - 160002  <br>
                                    Contact Person -
                                    <br> 
                                    MR. JITENDER SHARMA
                                    <br>
                                    8558899525,
8558899528
                                    <br> 
                                    gaya@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mt-n6 mr-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">JAMMU</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Jammu
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    H.N.-6 WARD NO.- 4 RAJEEV COLANY NEAR BISHNA ROAD BARI BRAHMNA OPP. ARMY TRAINING GROUND JAMMU-181133 <br>
                                    Contact Person -
                                    <br> 
                                    MR. SANDEEP KUMAR
                                    <br>
                                    8492950730, 
9018181978 
                                    <br> 
                                    bm_jammu@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row gap-y">
                        <div class="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"> <i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i>
                                <p class="bold mb-0">HIMACHAL PRADESH </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Baddi
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    KHARSA NO. 427/163/2 VILL.BHATOLI KHURD NEAR ABBOTT HEALTHCARE PVT.LTD. BIRLA UNICHEM ROAD TEH.BADDI DISTT.SOLAN(HP) BADDI (HP) PIN CODE-173205 <br>
                                    Contact Person -
                                    <br> 
                                    MR. PAWAN KUMAR  
                                    <br>
                                    7832940019, 7832940016
                                    <br> 
                                    baddi@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mr-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i>
                                <p class="bold mb-0">UTTARAKHAND </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Haridwar
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    PLOT NO. 41 SECTOR 11 I.I.E SIDCUL HARIDWAR-249403 <br>
                                    Contact Person -
                                    <br> 
                                    MR. SURYA KANT KAUSHIK 
                                    <br>
                                    8392921841,
8392921844 
                                    <br> 
                                    bm_haridwar@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 text-contrast bg-info-gradient shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">SIKKIM</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Rangpo
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    NEAR SMOKE TESTING CENTER. IBM RANGPO EAST SIKKIM PIN-737132  <br>
                                    Contact Person -
                                    <br> 
                                    MR.SANJAY KUMAR  
                                    <br>
                                    9073225847 
                                    <br> 
                                    rangpo@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mt-n6 mr-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">ARUNACHAL PRADESH</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Naharlagun
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    NATIONAL HIGHWAY 52A, CONA, NAHARLAGUN, ARUNACHAL PRADESH 791110, <br>
                                    Contact Person -
                                    <br> 
                                    Mr. BHARGOB GOGOI
                                    <br>
                                    9706769559 
                                    <br> 
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row gap-y">
                        <div class="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">MANIPUR</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Imphal
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    MANIPUR, IMPHAL AIRCEL, OTL IMPHAL, 895001  <br>
                                    Contact Person -
                                    <br> 
                                    MR. SANJAY KUMAR
                                    <br>
                                    7091598664 
                                    <br> 
                                   
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mr-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">ASSAM</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Guwahati
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    CARE OF SRI NRIPEN DAS BETAKUCHI BEHIND BHARGAV HOTEL DTO OFFICE OPP- TIRUPATI BALAJI TEMPLE N.H WAY 37 GUWAHATI- 781035 <br>
                                    Contact Person -
                                    <br> 
                                    MR.DALBIR POONIA  <br>
                                    7086049094 
                                    <br> 
                                    guwahati@omlogistics.co.in 
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 ml-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">PONDICHERRY </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Pondicherry
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    NO.29 1ST CROSS STREET JOHN KUMAR NAGAR MOOLAKULAM PUDUCHERRY-605010 <br>
                                    Contact Person -
                                    <br> 
                                    MR. K.BALAJI  
                                    <br>
                                    9244200145, 9655316741 
                                    <br> 
                                    bm_pondy@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mt-n6 pt-10 mr-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">ANDAMAN AND NICOBAR </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Andaman
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    37/2, 22 CHURCH LANE GOALGHAR PORT BLAIR TEHSIL SOUTH ANDAMAN -744104  <br>
                                    Contact Person -
                                    <br> 
                                    MR. SHIV KUMAR  
                                    <br>
                                    9073225835 
                                    <br> 
                                    andaman@omlogistics.co.in
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row gap-y">
                        <div class="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"> <i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i>
                                <p class="bold mb-0">JHARKHAND</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Jamshedpur
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    H. NO- 29& 30 BIRUPA ROAD, SAKCHI, JAMSHEDPUR-831001  <br>
                                    Contact Person -
                                    <br> 
                                    Mr. S.P. Mall  
                                    <br>
                                    7044091061   
                                    <br> 
                                    recycle@hulladek.pro
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mr-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i>
                                <p class="bold mb-0">ODISHA</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Bhubaneswar
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    KHARAVELANAGAR SCR- 65, JANPATH UNIT III, BHUBANESWAR 751001, ODISHA  <br>
                                    Contact Person -
                                    <br> 
                                    Mr. Narasingha Panigrahi
                                    <br>
                                    9124777722 
                                    <br> 
                                    narasingha@sgrgreen.com
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 text-contrast bg-info-gradient shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">ODISHA</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Bargarh
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    IMPERIAL COLLEGE BARGARH MARG, VIDYAVIHAR,CHADEIGAON, ODISHA-768038 <br>
                                    Contact Person -
                                    <br> 
                                    Mr. Deepak Goyal 
                                    <br>
                                    9937057180 
                                    <br> 
                                    ronak.a@imperial.edu.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mt-n6 mr-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">NEW DELHI</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Delhi
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    SAROJINI HOUSE, 6, BAGHWAN DAS ROAD, BAGHWAN DAS LANE, MANDI HOUSE, PIN CODE- 110001 <br>
                                    Contact Person -
                                    <br> 
                                    Ms, Pratibha Arya
                                    <br>
                                    011-43389100/101
                                    <br> 
                                    pratibha@aiwc.org.in
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row gap-y">
                        <div class="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">ASSAM</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Guwahati
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    UNITED GLOBAL TRUST, NEAR NISSAN SHOWROOM, LOKHRA, GUWAHATI- 781302 <br>
                                    Contact Person -
                                    <br> 
                                    Mr. Bhargob Gogoi
                                    <br>
                                    7091598664 
                                    <br> 
                                    ner@binbag.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mr-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">NAGALAND</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Dimapur
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own  Collection Centre <br>
                                    Address - 
                                    HOUSE 93, C KHEL, DIPHUPARA, DIMAPUR, NAGALAND 797001 <br>
                                    Contact Person -
                                    <br> 
                                    Ms. Soweteu K Letro
                                    <br>
                                    7005811236 
                                    <br> 
                                    contact@ecircle2018.com
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 ml-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">TRIPURA </p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Agartala
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    GANARAJ CHOWMUHANI, INDRANAGAR, AGARTALA, TRIPURA 799004 <br>
                                    Contact Person -
                                    <br> 
                                    Mr. Prasenjit Saha  
                                    <br>
                                    6913346620 
                                    <br> 
                                    goforgreenpartner@gmail.com
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mt-n6 mr-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">WEST BENGAL</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Kolkata
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    PREMISES NO 23A, CHARU CHANDRA PLACE EAST, KOLKATA-700033
 <br>
                                    Contact Person -
                                    <br> 
                                    Mr. Nandan Mall 
                                    <br>
                                    9836105003 
                                    <br> 
                                    recycle@hulladek.pro
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row gap-y">
                        <div class="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"> <i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i>
                                <p class="bold mb-0">WEST BENGAL</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Kolkata
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    B-248 LAKE TOWN ROAD (LAKE TOWN FOREST NURSERY) KOLKATA-700089 <br>
                                    Contact Person -
                                    <br> 
                                    Mrs. Shikha Mitra
                                    <br>
                                    9051958904 
                                    <br> 
                                    
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mr-lg-auto">
                            <div class="text-contrast bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i>
                                <p class="bold mb-0">WEST BENGAL</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Kolkata
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    AD 263/B, SECTOR 1, SALT ALKE, KOLKATA-700064 <br>
                                    Contact Person -
                                    <br> 
                                    Mrs. Bubu Basu  
                                    <br>
                                    9831086692 
                                    <br> 
                                    NEW
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 text-contrast bg-info-gradient shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">KARNATAKA</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Bangalore
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    No.185,1st MAIN ,1st CROSS, AZEEZ SAIT INDUSTRIAL ESTATE , NAYANDAHALLI , BANGALORE -560039    <br>
                                    Contact Person -
                                    <br> 
                                    Mr. Syed Ulla  
                                    <br>
                                    9945008827 
                                    <br> 
                                    ecobirdd@gmail.com 
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mt-n6 mr-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">TAMIL NADU</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Own Coimbatore
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Collection Centre <br>
                                    Address - 
                                    NP. lJ. SIYENARDHA
                                    INDUSTRIAL COMPLEX. Dr MS UDAVAMURTHY NASAR. THEDASAM ROAD. EDAVARMLAYAM, COIMBATORE- 641025 <br>
                                    Contact Person -
                                    <br> 
                                    Mr. Prasanth Omanakuttan  
                                    <br>
                                    9965664526 
                                    <br> 
                                    prasanth@thegreenera.in
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row gap-y">
                        <div class="col-6 col-sm-5 col-md-6 mt-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">HARYANA</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Haryana
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    CHARA MANDI ROAD, AMBALA CITY HARYANA- 134003 <br>
                                    Contact Person -
                                    <br> 
                                    Mr. Avneet Singh
                                    <br>
                                    8930307243 
                                    <br> 
                                  
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mr-lg-auto">
                            <div class="text-contrast pb-10 mb-10 bg-info-gradient card rounded p-2 p-sm-4 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">MEGHALAYA</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Shillong
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    OPPOSITE SYNOD COLLEGE MAIN GATE JAIAW LANGSNING SHILLONG-793002 <br>
                                    Contact Person -
                                    <br> 
                                    MR. LARRY NONG 
                                    <br>
                                    7091598664 
                                    <br> 
                                    7005886549
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 ml-lg-auto">
                            <div class="card rounded p-2 p-sm-4 text-contrast bg-info-gradient shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:rgb(37, 29, 29) ;" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">ODISHA</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Bargarh
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    IMPERIAL COLLEGE BARGARH MARG, VIDYAVIHAR,CHADEIGAON, ODISHA-768038 <br>
                                    Contact Person -
                                    <br> 
                                    Mr. Deepak Goyal 
                                    <br>
                                    9937057180 
                                    <br> 
                                    ronak.a@imperial.edu.in
                                    
                                </p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-5 col-md-6 mt-n6 mr-lg-auto">
                            <div class="card rounded  pb-2 pl-2 pr-2 p-sm-4 pt-15 shadow text-center text-md-left" data-aos="fade-up"><i class="fa fa-map-marker" style="font-size:48px;color:#0085e1" aria-hidden="true" width="36" height="36" ></i> 
                                <p class="bold mb-0">NEW DELHI</p>
                        
                                <p class="op-7 small">
                                    City/Town - 
                                    Delhi
                                    <br>
                                    Collection Centre/ Collection Point -
                                    Own Collection Centre <br>
                                    Address - 
                                    SAROJINI HOUSE, 6, BAGHWAN DAS ROAD, BAGHWAN DAS LANE, MANDI HOUSE, PIN CODE- 110001 <br>
                                    Contact Person -
                                    <br> 
                                    Ms, Pratibha Arya
                                    <br>
                                    011-43389100/101
                                    <br> 
                                    pratibha@aiwc.org.in
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
              
              
                
               
            </div>

            <h4 style=" margin-top:35px">
                TERMS & CONDITIONS:
            </h4>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">The service is available Monday to Friday from 10:00 hrs to 17:30 hrs.</li>
                <li class="list-group-item">The product will be safely recycled.</li>
                <li class="list-group-item">Please carry and deposit your product at the location address specified to you.</li>
                <li class="list-group-item">We are not obliged to reimburse the customer the scrap value/any other value of the E–waste product surrendered by the customer to the authorized collection centre or picked up from Customer’s place.</li>
                <li class="list-group-item">Local conditions of authorized recycler policy will apply.</li>
                <li class="list-group-item">We reserve the right to change / amend this policy</li>

            </ul>



        </div>
    </section>
</main>
