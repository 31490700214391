<body>
  


<div class="offcanvas-overlay"></div>

    <!-- Slider Start -->
    <div class="slider-area slider-height-1">
        <div class="hero-slider swiper-container">
            <div class="swiper-wrapper">
                <!-- Single Slider  -->
                <div class="swiper-slide bg-img d-flex" style="background-image: url(assets/images/npfinal/111.jpeg) ; ">
                    <div class="container align-self-center">
                        <div class="slider-content-1 slider-animated-1 text-left pl-60px">
                            <span class="animated color-gray">NEW DESIGN </span>
                            <h1 class="animated color-black">
                                HD Camera <br />
                                <strong>NPTECH</strong>
                            </h1>
                            <a href="contact" class="shop-btn animated">CONTACT NOW</a>
                        </div>
                    </div>
                </div>
                <!-- Single Slider  -->
                <div class="swiper-slide bg-img d-flex" style="background-image: url(assets/images/npfinal/555.jpeg);">
                    <div class="container align-self-center">
                        <div class="slider-content-1 slider-animated-1 text-left pl-60px">
                            <span class="animated color-gray">BILLING MACHINE</span>
                            <h1 class="animated color-black">
                                NP150 Counter <br />
                                <strong>NPTECH</strong>
                            </h1>
                            <a href="contact" class="shop-btn animated">CONTACT NOW</a>
                        </div>
                    </div>
                </div>
                <!-- Single Slider  -->
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination swiper-pagination-white"></div>
        </div>
    </div>
    <br><br>
    <!-- <div class="static-area mtb-60px">
        <div class="container">
            <div class="static-area-wrap">
                <div class="row">
                   
                    <div class="col-lg-3 col-xs-12 col-md-6 col-sm-6 mb-md-30px mb-lm-30px">
                        <div class="single-static">
                            <img src="assets/images/icons/static-icons-1.png" alt="" class="img-responsive" />
                            <div class="single-static-meta">
                                <h4>Free Shipping</h4>
                                <p>On all orders over ₹75.00</p>
                            </div>
                        </div>
                    </div>
                  
                    <div class="col-lg-3 col-xs-12 col-md-6 col-sm-6 mb-md-30px mb-lm-30px">
                        <div class="single-static">
                            <img src="assets/images/icons/static-icons-2.png" alt="" class="img-responsive" />
                            <div class="single-static-meta">
                                <h4>Free Returns</h4>
                                <p>Returns are free within 9 days</p>
                            </div>
                        </div>
                    </div>
                 
                    <div class="col-lg-3 col-xs-12 col-md-6 col-sm-6 mb-sm-30px">
                        <div class="single-static">
                            <img src="assets/images/icons/static-icons-4.png" alt="" class="img-responsive" />
                            <div class="single-static-meta">
                                <h4>Support 24/7</h4>
                                <p>Contact us 24 hours a day</p>
                            </div>
                        </div>
                    </div>
                 
                    <div class="col-lg-3 col-xs-12 col-md-6 col-sm-6">
                        <div class="single-static">
                            <img src="assets/images/icons/static-icons-3.png" alt="" class="img-responsive" />
                            <div class="single-static-meta">
                                <h4>100% Payment Secure</h4>
                                <p>Your payment are safe with us.</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div> -->
    <!-- Static Area End -->
    <!-- Banner Area Start -->
    <div class="banner-area">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-xs-12">
                    <div class="banner-wrapper">
                        <a href="#"><img  src="assets/images/NPTECH/IMG-20200730-WA0009.jpg" width="450px" height="250px"  alt="" /></a>
                    </div>
                </div>
                <div class="col-md-4 col-xs-12">
                    <div class="banner-wrapper">
                        <a href="#"><img src="assets/images/NPTECH/IMG-20200730-WA0012.jpg"  width="450px" height="250px" alt="" /></a>
                    </div>
                </div>
                <div class="col-md-4 col-xs-12">
                    <div class="banner-wrapper">
                        <a href="#"><img src="assets/images/NPTECH/IMG-20200730-WA0059.jpg"  width="450px" height="250px" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End -->
    <!-- category Area Start -->
    <div class="popular-categories-area mt-30px">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-title">
                        <h2 class="section-heading">Our Products</h2>
                    </div>
                </div>
            </div>
            <div class="category-slider slider-nav-style-1">
                <div class="category-slider-wrapper swiper-wrapper">
                    <div class="category-slider-item swiper-slide">
                        <div class="thumb-category">
                            <a href="#">
                                <img width="258px" height="170px" src="assets/images/NPTECH/IMG-20200730-WA0023.jpg" alt="product-image.jpg" />
                            </a>
                        </div>
                        <div class="category-discript">
                            <h4>Speakers</h4>
                            <!-- <ul>
                                <li><a href="#">Wearable Devices</a></li>
                                <li><a href="#">Smart Home Appliances</a></li>
                                <li><a href="#">Smart Remote Controls</a></li>
                                <li><a href="#">Smart Watches</a></li>
                            </ul> -->
                            <!-- <a href="" class="view-all-btn">View All</a> -->
                        </div>
                    </div>
                    <div class="category-slider-item swiper-slide">
                        <div class="thumb-category">
                            <a href="#">
                                <img width="258px" height="170px" src="assets/images/NPTECH/IMG-20200730-WA0005.jpg" alt="product-image.jpg" />
                            </a>
                        </div>
                        <div class="category-discript">
                            <h4>Motorized Screen</h4>
                            <!-- <ul>
                                <li><a href="#">Televisions</a></li>
                                <li><a href="#">TV Receivers</a></li>
                                <li><a href="#">Projectors</a></li>
                                <li><a href="#">TV Sticks</a></li>
                            </ul> -->
                            <!-- <a href="" class="view-all-btn">View All</a> -->
                        </div>
                    </div>
                    <div class="category-slider-item swiper-slide">
                        <div class="thumb-category">
                            <a href="#">
                                <img width="258px" height="170px" src="assets/images/NPTECH/IMG-20200730-WA0019.jpg" alt="product-image.jpg" />
                            </a>
                        </div>
                        <div class="category-discript">
                            <h4>Monitor</h4>
                            <!-- <ul>
                                <li><a href="#">Handheld Game Players</a></li>
                                <li><a href="#">Game Controllers</a></li>
                                <li><a href="#">Joysticks</a></li>
                                <li><a href="#">Stickers</a></li>
                            </ul> -->
                            <!-- <a href="" class="view-all-btn">View All</a> -->
                        </div>
                    </div>
                    <div class="category-slider-item swiper-slide">
                        <div class="thumb-category">
                            <a href="#">
                                <img width="258px" height="170px" src="assets/images/NPTECH/IMG-20200730-WA0011.jpg" alt="product-image.jpg" />
                            </a>
                        </div>
                        <div class="category-discript">
                            <h4>Imaging Solution</h4>
                            <!-- <ul>
                                <li><a href="#">Headphones</a></li>
                                <li><a href="#">Speakers</a></li>
                                <li><a href="#">MP3 Players</a></li>
                                <li><a href="#">Microphones</a></li>
                            </ul> -->
                            <!-- <a href="" class="view-all-btn">View All</a> -->
                        </div>
                    </div>
                    <div class="category-slider-item swiper-slide">
                        <div class="thumb-category">
                            <a href="#">
                                <img width="258px" height="170px" src="assets/images/NPTECH/IMG-20200730-WA0062.jpg" alt="product-image.jpg" />
                            </a>
                        </div>
                        <div class="category-discript">
                            <h4>Billing Machine</h4>
                            <!-- <ul>
                                <li><a href="#">Digital Cameras</a></li>
                                <li><a href="#">Camcorders</a></li>
                                <li><a href="#">Camera Drones</a></li>
                                <li><a href="#">Action Cameras</a></li>
                            </ul> -->
                            <!-- <a href="" class="view-all-btn">View All</a> -->
                        </div>
                    </div>
                    <div class="category-slider-item swiper-slide">
                        <div class="thumb-category">
                            <a href="#">
                                <img width="258px" height="170px" src="assets/images/NPTECH/IMG-20200730-WA0035.jpg" alt="product-image.jpg" />
                            </a>
                        </div>
                        <div class="category-discript">
                            <h4>Other Accessories</h4>
                            <!-- <ul>
                                <li><a href="#">Wearable Devices</a></li>
                                <li><a href="#">Smart Home Appliances</a></li>
                                <li><a href="#">Smart Remote Controls</a></li>
                                <li><a href="#">Smart Watches</a></li>
                            </ul> -->
                            <!-- <a href="" class="view-all-btn">View All</a> -->
                        </div>
                    </div>
                </div>
                <!-- Add Arrows -->
                <div class="swiper-buttons">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- category Area End -->
    <!-- Feature Area start -->
    <!-- <div class="feature-area mt-60px mb-30px">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-title">
                        <h2 class="section-heading">Featured Products</h2>
                    </div>
                </div>
            </div>
            <div class="feature-slider slider-nav-style-1">
                <div class="feature-slider-wrapper swiper-wrapper">
                
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                   
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/7.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/8.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price">₹23.90</li>
                                        <li class="current-price">₹21.51</li>
                                        <li class="discount-price">-10%</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
            
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/9.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/10.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
               
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                 
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                   
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/16.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/17.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                  
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/18.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/19.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
               
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/20.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/21.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                   
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/22.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/23.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                 
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/24.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/25.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                   
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/26.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/27.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/28.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/29.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                  
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/8.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/9.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                   
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/10.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/11.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                 
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                   
                    <div class="feature-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </div>
             
                <div class="swiper-buttons">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Feature Area End -->
    <!-- Deal Area Start -->
    <!-- <div class="deal-area bg-light-gray pt-60px pb-30px">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-title">
                        <h2 class="section-heading">Deals Of The Day</h2>
                    </div>
                </div>
            </div>
            <div class="deal-slider slider-nav-style-1">
                <div class="deal-slider-wrapper swiper-wrapper">
                    
                    <div class="deal-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/29.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/17.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price">₹23.90</li>
                                        <li class="current-price">₹21.51</li>
                                        <li class="discount-price">-10%</li>
                                    </ul>
                                </div>
                                <div class="availability-list in-stock">Availability: <span>300 In Stock</span></div>
                                <div class="clockdiv">
                                    <div data-countdown="2021/03/01"></div>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                    
                    <div class="deal-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/21.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/23.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price">₹23.90</li>
                                        <li class="current-price">₹21.51</li>
                                        <li class="discount-price">-10%</li>
                                    </ul>
                                </div>
                                <div class="availability-list in-stock">Availability: <span>300 In Stock</span></div>
                                <div class="clockdiv">
                                    <div data-countdown="2021/03/01"></div>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                
                    <div class="deal-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/9.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/28.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price">₹23.90</li>
                                        <li class="current-price">₹21.51</li>
                                        <li class="discount-price">-10%</li>
                                    </ul>
                                </div>
                                <div class="availability-list in-stock">Availability: <span>300 In Stock</span></div>
                                <div class="clockdiv">
                                    <div data-countdown="2021/03/01"></div>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                    
                    <div class="deal-slider-item swiper-slide">
                        <article class="list-product">
                            <div class="img-block">
                                <a href="#" class="thumbnail">
                                    <img class="first-img" src="assets/images/product-image/8.jpg" alt="" />
                                    <img class="second-img" src="assets/images/product-image/10.jpg" alt="" />
                                </a>
                                <div class="quick-view">
                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                        <i class="icon-magnifier icons"></i>
                                    </a>
                                </div>
                            </div>
                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>
                            <div class="product-decs">
                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                <div class="rating-product">
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                    <i class="ion-android-star"></i>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price">₹23.90</li>
                                        <li class="current-price">₹21.51</li>
                                        <li class="discount-price">-10%</li>
                                    </ul>
                                </div>
                                <div class="availability-list in-stock">Availability: <span>300 In Stock</span></div>
                                <div class="clockdiv">
                                    <div data-countdown="2021/03/01"></div>
                                </div>
                            </div>
                            <div class="add-to-link">
                                <ul>
                                    <li class="cart"><a class="cart-btn" href="#">ADD TO CART </a></li>
                                    <li>
                                        <a href="wishlist.html"><i class="icon-heart"></i></a>
                                    </li>
                                    <li>
                                        <a href="compare.html"><i class="icon-shuffle"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </div>
                
                <div class="swiper-buttons">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
            </div>
        </div>
        
        <div class="banner-area mt-30px">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="banner-wrapper">
                            <a href=""><img src="assets/images/banner-image/4.jpg" alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </div> -->
    <!-- Deal Area End -->
    <!-- Category Tab Area Start -->
    <!-- <section class="category-tab-area mt-60px mb-40px">
        <div class="container">
            <div class="section-title d-flex">
                <h2>Audio & Video</h2>
                
                <ul class="nav nav-tabs sub-category d-flex justify-content-end flex-grow-1">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tab-1">Televisions</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tab-2">TV Receivers</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tab-3">Projectors</a>
                    </li>
                </ul>
            </div>

            
            <div class="tab-content banner-area">
              
                <div id="tab-1" class="tab-pane active">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 mt-lm-55px">
                            <div class="banner-wrapper">
                                <a href=""><img src="assets/images/banner-image/5.jpg" alt="" /></a>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8">
                            <div class="feature-slider-2 slider-nav-style-1">
                                <div class="feature-slider-wrapper swiper-wrapper">
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/7.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/8.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price">₹23.90</li>
                                                        <li class="current-price">₹21.51</li>
                                                        <li class="discount-price">-10%</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/9.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/10.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/16.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/17.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/18.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/19.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/20.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/21.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/22.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/23.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/24.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/25.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/26.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/27.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/28.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/29.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/8.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/9.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/10.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/11.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                
                                <div class="swiper-buttons">
                                    <div class="swiper-button-next"></div>
                                    <div class="swiper-button-prev"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="tab-2" class="tab-pane fade">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 mt-lm-55px">
                            <div class="banner-wrapper">
                                <a href=""><img src="assets/images/banner-image/5.jpg" alt="" /></a>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8">
                            <div class="feature-slider-2 slider-nav-style-1">
                                <div class="feature-slider-wrapper swiper-wrapper">
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/7.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/8.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price">₹23.90</li>
                                                        <li class="current-price">₹21.51</li>
                                                        <li class="discount-price">-10%</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/9.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/10.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/16.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/17.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/18.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/19.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/20.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/21.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/22.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/23.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/24.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/25.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/26.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/27.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/28.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/29.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/8.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/9.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/10.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/11.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                               
                                <div class="swiper-buttons">
                                    <div class="swiper-button-next"></div>
                                    <div class="swiper-button-prev"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
                <div id="tab-3" class="tab-pane fade">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 mt-lm-55px">
                            <div class="banner-wrapper">
                                <a href=""><img src="assets/images/banner-image/5.jpg" alt="" /></a>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8">
                            <div class="feature-slider-2 slider-nav-style-1">
                                <div class="feature-slider-wrapper swiper-wrapper">
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/7.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/8.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price">₹23.90</li>
                                                        <li class="current-price">₹21.51</li>
                                                        <li class="discount-price">-10%</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/9.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/10.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/16.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/17.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/18.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/19.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/20.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/21.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/22.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/23.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/24.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/25.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/26.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/27.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/28.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/29.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/8.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/9.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/10.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/11.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                               
                                <div class="swiper-buttons">
                                    <div class="swiper-button-next"></div>
                                    <div class="swiper-button-prev"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </section> -->
    <!-- Category Tab Area end -->
    <!-- Category Tab Area Start -->
    <!-- <section class="category-tab-area mb-40px">
        <div class="container">
            <div class="section-title d-flex">
                <h2>Camera & Photo</h2>
                
                <ul class="nav nav-tabs sub-category d-flex justify-content-end flex-grow-1">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tab-4">Digital Cameras</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tab-5">Camcorders</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tab-6">Camera Drones</a>
                    </li>
                </ul>
            </div>

          
            <div class="tab-content banner-area">
              
                <div id="tab-4" class="tab-pane active">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 mt-lm-55px">
                            <div class="banner-wrapper">
                                <a href=""><img src="assets/images/banner-image/6.jpg" alt="" /></a>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8">
                            <div class="feature-slider-2 slider-nav-style-1">
                                <div class="feature-slider-wrapper swiper-wrapper">
                               
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/7.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/8.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price">₹23.90</li>
                                                        <li class="current-price">₹21.51</li>
                                                        <li class="discount-price">-10%</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                               
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/9.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/10.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                  
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                   
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/16.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/17.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                   
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/18.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/19.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                 
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/20.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/21.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>

                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/22.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/23.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                   
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/24.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/25.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                 
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/26.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/27.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                 
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/28.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/29.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                               
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/8.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/9.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                 
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/10.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/11.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                   
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                              
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                              
                                <div class="swiper-buttons">
                                    <div class="swiper-button-next"></div>
                                    <div class="swiper-button-prev"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             
                <div id="tab-5" class="tab-pane fade">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 mt-lm-55px">
                            <div class="banner-wrapper">
                                <a href=""><img src="assets/images/banner-image/6.jpg" alt="" /></a>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8">
                            <div class="feature-slider-2 slider-nav-style-1">
                                <div class="feature-slider-wrapper swiper-wrapper">
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/7.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/8.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price">₹23.90</li>
                                                        <li class="current-price">₹21.51</li>
                                                        <li class="discount-price">-10%</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/9.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/10.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/16.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/17.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/18.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/19.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/20.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/21.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/22.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/23.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/24.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/25.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/26.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/27.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/28.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/29.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/8.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/9.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/10.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/11.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                               
                                <div class="swiper-buttons">
                                    <div class="swiper-button-next"></div>
                                    <div class="swiper-button-prev"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
                <div id="tab-6" class="tab-pane fade">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 mt-lm-55px">
                            <div class="banner-wrapper">
                                <a href=""><img src="assets/images/banner-image/6.jpg" alt="" /></a>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8">
                            <div class="feature-slider-2 slider-nav-style-1">
                                <div class="feature-slider-wrapper swiper-wrapper">
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/7.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/8.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price">₹23.90</li>
                                                        <li class="current-price">₹21.51</li>
                                                        <li class="discount-price">-10%</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/9.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/10.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/16.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/17.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/18.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/19.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/20.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/21.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/22.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/23.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/24.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/25.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/26.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/27.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/28.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/29.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/6.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/7.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/8.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/9.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/10.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/11.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/12.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/13.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                    
                                    <div class="feature-slider-item swiper-slide">
                                        <article class="list-product">
                                            <div class="img-block">
                                                <a href="#" class="thumbnail">
                                                    <img class="first-img" src="assets/images/product-image/14.jpg" alt="" />
                                                    <img class="second-img" src="assets/images/product-image/15.jpg" alt="" />
                                                </a>
                                                <div class="quick-view">
                                                    <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="icon-magnifier icons"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <ul class="product-flag">
                                                <li class="new">New</li>
                                            </ul>
                                            <div class="product-decs">
                                                <a class="inner-link" href=""><span>NPTECH</span></a>
                                                <h2><a href="#" class="product-link">New Balance Arishi Sport v1</a></h2>
                                                <div class="rating-product">
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                    <i class="ion-android-star"></i>
                                                </div>
                                                <div class="pricing-meta">
                                                    <ul>
                                                        <li class="old-price not-cut">₹18.90</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="add-to-link">
                                                <ul>
                                                    <li class="cart"><a title="Add to cart" href="#"><i class="icon-bag"></i></a></li>
                                                    <li>
                                                        <a title="Add to wishlist" href="wishlist.html"><i class="icon-heart"></i></a>
                                                    </li>
                                                    <li>
                                                        <a title="Add to compare" href="compare.html"><i class="icon-shuffle"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                              
                                <div class="swiper-buttons">
                                    <div class="swiper-button-next"></div>
                                    <div class="swiper-button-prev"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           
            </div>
        </div>
    </section> -->
    <!-- Category Tab Area end -->
        <!-- Brand area start -->
        <!-- <div class="brand-area mb-60px">
            <div class="container">
                <div class="brand-slider slider-nav-style-1 slider-nav-style-2 ">
                    <div class="brand-slider-wrapper swiper-wrapper">
                        <div class="brand-slider-item swiper-slide">
                            <a href="#"><img src="assets/images/brand-logo/1.jpg" alt="" /></a>
                        </div>
                        <div class="brand-slider-item swiper-slide">
                            <a href="#"><img src="assets/images/brand-logo/2.jpg" alt="" /></a>
                        </div>
                        <div class="brand-slider-item swiper-slide">
                            <a href="#"><img src="assets/images/brand-logo/3.jpg" alt="" /></a>
                        </div>
                        <div class="brand-slider-item swiper-slide">
                            <a href="#"><img src="assets/images/brand-logo/4.jpg" alt="" /></a>
                        </div>
                        <div class="brand-slider-item swiper-slide">
                            <a href="#"><img src="assets/images/brand-logo/5.jpg" alt="" /></a>
                        </div>
                        <div class="brand-slider-item swiper-slide">
                            <a href="#"><img src="assets/images/brand-logo/1.jpg" alt="" /></a>
                        </div>
                        <div class="brand-slider-item swiper-slide">
                            <a href="#"><img src="assets/images/brand-logo/2.jpg" alt="" /></a>
                        </div>
                    </div>
                    
                    <div class="swiper-buttons">
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Brand area end -->
        <br> <br>
        <!-- Recently Add area start -->
        <div class="recent-add-area mb-60px">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section-title">
                            <h2 class="section-heading">Recently Added</h2>
                        </div>
                    </div>
                </div>
                <div class="recent-slider slider-nav-style-1">
                    <div class="recent-slider-wrapper swiper-wrapper">
                        <div class="recent-slider-item swiper-slide">
                            <article class="list-product">
                                <div class="img-block">
                                    <a href="#" class="thumbnail">
                                        <img class="first-img" src="assets/images/NPTECH/IMG-20200730-WA0036.jpg" alt="" />
                                        <!-- <img class="second-img" src="assets/images/product-image/29.jpg" alt="" /> -->
                                    </a>
                                    <!-- <div class="quick-view">
                                        <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                            <i class="icon-magnifier icons"></i>
                                        </a>
                                    </div> -->
                                </div>
                                <div class="product-decs">
                                    <a class="inner-link" href=""><span>NPTECH</span></a>
                                    <h2><a href="#" class="product-link">HD Video <br> Convertor</a></h2>
                                    <div class="rating-product">
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                    </div>
                                    <!-- <div class="pricing-meta">
                                        <ul>
                                            <li class="old-price not-cut">₹18.90</li>
                                        </ul>
                                    </div> -->
                                </div>
                            </article>
                        </div>
                        <div class="recent-slider-item swiper-slide">
                            <article class="list-product">
                                <div class="img-block">
                                    <a href="#" class="thumbnail">
                                        <img class="first-img" src="assets/images/NPTECH/IMG-20200730-WA0035.jpg" alt="" />
                                        <!-- <img class="second-img" src="assets/images/product-image/10.jpg" alt="" /> -->
                                    </a>
                                    <!-- <div class="quick-view">
                                        <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                            <i class="icon-magnifier icons"></i>
                                        </a>
                                    </div> -->
                                </div>
                                <div class="product-decs">
                                    <a class="inner-link" href=""><span>NPTECH</span></a>
                                    <h2><a href="#" class="product-link">Pluggable USB <br> LAN</a></h2>
                                    <div class="rating-product">
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                    </div>
                                    <!-- <div class="pricing-meta">
                                        <ul>
                                            <li class="old-price">₹23.90</li>
                                            <li class="current-price">₹21.51</li>
                                            <li class="discount-price">-10%</li>
                                        </ul>
                                    </div> -->
                                </div>
                            </article>
                        </div>
                        <div class="recent-slider-item swiper-slide">
                            <article class="list-product">
                                <div class="img-block">
                                    <a href="#" class="thumbnail">
                                        <img class="first-img" src="assets/images/NPTECH/IMG-20200730-WA0044.jpg" alt="" />
                                        <!-- <img class="second-img" src="assets/images/product-image/16.jpg" alt="" /> -->
                                    </a>
                                    <!-- <div class="quick-view">
                                        <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                            <i class="icon-magnifier icons"></i>
                                        </a>
                                    </div> -->
                                </div>
                                <div class="product-decs">
                                    <a class="inner-link" href=""><span>NPTECH</span></a>
                                    <h2><a href="#" class="product-link">HDD External <br> Case</a></h2>
                                    <div class="rating-product">
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                    </div>
                                    <!-- <div class="pricing-meta">
                                        <ul>
                                            <li class="old-price not-cut">₹18.90</li>
                                        </ul>
                                    </div> -->
                                </div>
                            </article>
                        </div>
                        <div class="recent-slider-item swiper-slide">
                            <article class="list-product">
                                <div class="img-block">
                                    <a href="#" class="thumbnail">
                                        <img class="first-img" src="assets/images/NPTECH/IMG-20200730-WA0059.jpg" alt="" />
                                        <!-- <img class="second-img" src="assets/images/product-image/18.jpg" alt="" /> -->
                                    </a>
                                    <!-- <div class="quick-view">
                                        <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                            <i class="icon-magnifier icons"></i>
                                        </a>
                                    </div> -->
                                </div>
                                <div class="product-decs">
                                    <a class="inner-link" href=""><span>NPTECH</span></a>
                                    <h2><a href="#" class="product-link">Master <br> Cooler</a></h2>
                                    <div class="rating-product">
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                    </div>
                                    <!-- <div class="pricing-meta">
                                        <ul>
                                            <li class="old-price not-cut">₹18.90</li>
                                        </ul>
                                    </div> -->
                                </div>
                            </article>
                        </div>
                        <div class="recent-slider-item swiper-slide">
                            <article class="list-product">
                                <div class="img-block">
                                    <a href="#" class="thumbnail">
                                        <img class="first-img" src="assets/images/NPTECH/IMG-20200730-WA0034.jpg" alt="" />
                                        <!-- <img class="second-img" src="assets/images/product-image/20.jpg" alt="" /> -->
                                    </a>
                                    <!-- <div class="quick-view">
                                        <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                            <i class="icon-magnifier icons"></i>
                                        </a>
                                    </div> -->
                                </div>
                                <div class="product-decs">
                                    <a class="inner-link" href=""><span>NPTECH</span></a>
                                    <h2><a href="#" class="product-link">Monitor <br> Mount</a></h2>
                                    <div class="rating-product">
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                    </div>
                                    <!-- <div class="pricing-meta">
                                        <ul>
                                            <li class="old-price not-cut">₹18.90</li>
                                        </ul>
                                    </div> -->
                                </div>
                            </article>
                        </div>
                        <div class="recent-slider-item swiper-slide">
                            <article class="list-product">
                                <div class="img-block">
                                    <a href="#" class="thumbnail">
                                        <img class="first-img" src="assets/images/NPTECH/IMG-20200730-WA0062.jpg" alt="" />
                                        <!-- <img class="second-img" src="assets/images/product-image/22.jpg" alt="" /> -->
                                    </a>
                                    <!-- <div class="quick-view">
                                        <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                            <i class="icon-magnifier icons"></i>
                                        </a>
                                    </div> -->
                                </div>
                                <div class="product-decs">
                                    <a class="inner-link" href=""><span>NPTECH</span></a>
                                    <h2><a href="#" class="product-link">Billing <br> Machine</a></h2>
                                    <div class="rating-product">
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                    </div>
                                    <!-- <div class="pricing-meta">
                                        <ul>
                                            <li class="old-price">₹23.90</li>
                                            <li class="current-price">₹21.51</li>
                                            <li class="discount-price">-10%</li>
                                        </ul>
                                    </div> -->
                                </div>
                            </article>
                        </div>
                        <div class="recent-slider-item swiper-slide">
                            <article class="list-product">
                                <div class="img-block">
                                    <a href="#" class="thumbnail">
                                        <img class="first-img" src="assets/images/NPTECH/IMG-20200730-WA0011.jpg" alt="" />
                                        <!-- <img class="second-img" src="assets/images/product-image/24.jpg" alt="" /> -->
                                    </a>
                                    <!-- <div class="quick-view">
                                        <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                            <i class="icon-magnifier icons"></i>
                                        </a>
                                    </div> -->
                                </div>
                                <div class="product-decs">
                                    <a class="inner-link" href=""><span>NPTECH</span></a>
                                    <h2><a href="#" class="product-link">Dark Black <br> Ink </a></h2>
                                    <div class="rating-product">
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                    </div>
                                    <!-- <div class="pricing-meta">
                                        <ul>
                                            <li class="old-price not-cut">₹18.90</li>
                                        </ul>
                                    </div> -->
                                </div>
                            </article>
                        </div>
                        <div class="recent-slider-item swiper-slide">
                            <article class="list-product">
                                <div class="img-block">
                                    <a href="#" class="thumbnail">
                                        <img class="first-img" src="assets/images/NPTECH/IMG-20200730-WA0051.jpg" alt="" />
                                        <!-- <img class="second-img" src="assets/images/product-image/26.jpg" alt="" /> -->
                                    </a>
                                    <!-- <div class="quick-view">
                                        <a class="quick_view" href="#" data-link-action="quickview" title="Quick view" data-toggle="modal" data-target="#exampleModal">
                                            <i class="icon-magnifier icons"></i>
                                        </a>
                                    </div> -->
                                </div>
                                <div class="product-decs">
                                    <a class="inner-link" href=""><span>NPTECH</span></a>
                                    <h2><a href="#" class="product-link">Multimedia <br> Speakers </a></h2>
                                    <div class="rating-product">
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                    </div>
                                    <!-- <div class="pricing-meta">
                                        <ul>
                                            <li class="old-price not-cut">₹18.90</li>
                                        </ul>
                                    </div> -->
                                </div>
                            </article>
                        </div>
                       
                    </div>
                    <!-- Add Arrows -->
                    <div class="swiper-buttons">
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Recently Add area end -->
    <!-- Banner Area Start -->
    <div class="banner-area mb-30px">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-xs-12">
                    <div class="banner-wrapper">
                        <a href=""><img width=" 544.09px" height="186.22px" src="assets/images/npfinal/WhatsApp Image 2020-08-13 at 3.15.08 PM.jpeg" alt="" /></a>
                    </div>
                </div>
                <div class="col-md-6 col-xs-12">
                    <div class="banner-wrapper">
                        <a href=""><img width=" 544.09px" height="186.22px" src="assets/images/npfinal/WhatsApp Image 2020-08-13 at 3.15.08 PM (2).jpeg" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End -->
 
    <!-- Modal -->
    <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">x</span></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-5 col-sm-12 col-xs-12 mb-lm-100px mb-sm-30px">
                          
                              <div class="swiper-container gallery-top">
                                    <div class="swiper-wrapper">
                                      <div class="swiper-slide"> 
                                            <img class="img-responsive m-auto" src="assets/images/product-image/11.jpg" alt="">
                                      </div>
                                      <div class="swiper-slide"> 
                                            <img class="img-responsive m-auto" src="assets/images/product-image/12.jpg" alt="">
                                      </div>
                                      <div class="swiper-slide"> 
                                            <img class="img-responsive m-auto" src="assets/images/product-image/13.jpg" alt="">
                                      </div>
                                      <div class="swiper-slide"> 
                                            <img class="img-responsive m-auto" src="assets/images/product-image/14.jpg" alt="">
                                      </div>
                                    </div>
                              </div>
                              <div class="swiper-container gallery-thumbs">
                                <div class="swiper-wrapper">
                                      <div class="swiper-slide"> 
                                            <img class="img-responsive m-auto" src="assets/images/product-image/11.jpg" alt="">
                                      </div>
                                      <div class="swiper-slide"> 
                                            <img class="img-responsive m-auto" src="assets/images/product-image/12.jpg" alt="">
                                      </div>
                                      <div class="swiper-slide"> 
                                            <img class="img-responsive m-auto" src="assets/images/product-image/13.jpg" alt="">
                                      </div>
                                      <div class="swiper-slide"> 
                                            <img class="img-responsive m-auto" src="assets/images/product-image/14.jpg" alt="">
                                      </div>
                                    </div>
                              </div>
                        </div>
                        <div class="col-md-7 col-sm-12 col-xs-12">
                            <div class="product-details-content quickview-content">
                                <h2>Originals Kaval Windbr</h2>
                                <p class="reference">Reference:<span> demo_17</span></p>
                                <div class="pro-details-rating-wrap">
                                    <div class="rating-product">
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                        <i class="ion-android-star"></i>
                                    </div>
                                    <span class="read-review"><a class="reviews" href="#">Read reviews (1)</a></span>
                                </div>
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price not-cut">₹18.90</li>
                                    </ul>
                                </div>
                                <p class="quickview-para">Lorem ipsum dolor sit amet, consectetur adipisic elit eiusm tempor incidid ut labore et dolore magna aliqua. Ut enim ad minim venialo quis nostrud exercitation ullamco</p>
                                <div class="pro-details-size-color">
                                    <div class="pro-details-color-wrap">
                                        <span>Color</span>
                                        <div class="pro-details-color-content">
                                            <ul>
                                                <li class="blue"></li>
                                                <li class="maroon active"></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="pro-details-quality">
                                    <div class="cart-plus-minus">
                                        <input class="cart-plus-minus-box" type="text" name="qtybutton" value="1" />
                                    </div>
                                    <div class="pro-details-cart btn-hover">
                                        <a href="#"> + Add To Cart</a>
                                    </div>
                                </div>
                                <div class="pro-details-wish-com">
                                    <div class="pro-details-wishlist">
                                        <a href="wishlist.html"><i class="ion-android-favorite-outline"></i>Add to wishlist</a>
                                    </div>
                                    <div class="pro-details-compare">
                                        <a href="compare.html"><i class="ion-ios-shuffle-strong"></i>Add to compare</a>
                                    </div>
                                </div>
                                <div class="pro-details-social-info">
                                    <span>Share</span>
                                    <div class="social-info">
                                        <ul>
                                            <li>
                                                <a href="#"><i class="ion-social-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i class="ion-social-twitter"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i class="ion-social-google"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i class="ion-social-instagram"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Modal end -->
    <!-- JS
============================================ -->

        <!-- Vendors JS -->
    <!-- <script src="assets/js/vendor/jquery-3.5.1.min.js"></script>
    <script src="assets/js/vendor/bootstrap.bundle.min.js"></script>
    <script src="assets/js/vendor/jquery-migrate-3.1.0.min.js"></script>
    <script src="assets/js/vendor/modernizr-3.6.0.min.js"></script> -->

        <!-- Plugins JS -->
     <!-- <script src="assets/js/plugins/jquery-ui.min.js"></script>
    <script src="assets/js/plugins/swiper.min.js"></script>
    <script src="assets/js/plugins/countdown.js"></script>
    <script src="assets/js/plugins/scrollup.js"></script>
    <script src="assets/js/plugins/elevateZoom.js"></script> -->

    <!-- Use the minified version files listed below for better performance and remove the files listed above -->
    <!-- <script src="assets/js/vendor/vendor.min.js"></script>
    <script src="assets/js/plugins/plugins.min.js"></script>

   
    <script src="assets/js/main.js"></script> -->
</body>
