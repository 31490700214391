<header class="page header text-contrast overlay alpha-8 image-background cover gradient gradient-purple-blue" style="background-image: url('img/bg/waves.jpg')">
    <div class="divider-shape">
        <!-- waves divider --> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" class="shape-waves" style="left: 0; transform: rotate3d(0,1,0,180deg);">
            <path class="shape-fill shape-fill-contrast" 
            d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z" />
        </svg>
    </div>
    <div class="container" >
        <div class="row">
            <div class="col-md-6">
                <h1 class="regular display-4 text-contrast mb-4">About</h1>
                <p class="lead text-contrast"> 
                    New Peripherals Technology Pvt. Ltd,  an IT & Electronics Consumables Company was incorporated in the year 2015 with its flagship Brand “ NPTECH “.
                    
                </p>
            </div>
        </div>
    </div>
</header><!-- ./Overview - Floating boxes -->

<section class="about-area mtb-60px">
    <div class="container">
        <div class="row">
            <div class="col-lg-6"  >
                <div class="about-left-image mb-md-30px mb-lm-30px ">
                    <img   height=" 500px" src="assets/images/NPTECH/IMG-20200730-WA0023.jpg" alt="" class="img-responsive" />
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="about-title">
                        <h2>Welcome To NPTECH</h2>
                    </div>
                    <!-- <p class="mb-30px">
                        New Peripherals Technology Pvt. Ltd,  an IT & Electronics Consumables Company was incorporated in the year 2015 with its flagship Brand “ NPTECH “.
                    </p> -->
                    <p class="mb-30px" >
                        Teamwork , Innovation, Passion – The Brand NPTECH stands with these three pillars of strength which will help us to write a successful story not just for the Company alone but for the entire network associated with the brand. The Vision of the Company is to make itself a globally respected name in the field of IT & Electronics Products and to improve the quality of peoples life by serving them with quality and innovative products.
                    </p>
                    <p class="mb-30px">
                        The Company is entering into market with a motto of consistent policy of transparent, fair and ethical trade practices. The Company is dedicated to bring high quality products at competitive prices with efficient service.

                    </p>
                    <p class="mb-30px">
                        The Company is one of the pioneers in technology products. The company has a vast portfolio of Computer Peripherals , Accessories & Office Automation products.
                    </p>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-60px">
            <div class="col-md-4 mb-lm-30px">
                <div class="single-about">
                    <h4>Our Company</h4>
                    <p>
                        Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet conse ctetur adipisicing
                        elit.
                    </p>
                </div>
            </div>
            <div class="col-md-4 mb-lm-30px">
                <div class="single-about">
                    <h4>Our Team</h4>
                    <p>
                        Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet conse ctetur adipisicing
                        elit.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="single-about">
                    <h4>Testimonial</h4>
                    <p>
                        Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet conse ctetur adipisicing
                        elit.
                    </p>
                </div>
            </div>
        </div> -->
    </div>
</section>