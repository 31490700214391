  <!-- Header Section Start From Here -->
  
  <header class="header-wrapper">
    <!-- Header Nav Start -->
    <!-- <div class="header-nav">
        <div class="container">
            <div class="header-nav-wrapper d-md-flex d-sm-flex d-xl-flex d-lg-flex justify-content-between">
                <div class="header-static-nav">
                    <p>Welcome you the Eyot Store!</p>
                </div>
                <div class="header-menu-nav">
                    <ul class="menu-nav">
                        <li>
                            <div class="dropdown">
                                <button type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Setting <i class="ion-ios-arrow-down"></i></button>

                                <ul class="dropdown-menu animation slideDownIn" aria-labelledby="dropdownMenuButton">
                                    <li><a href="my-account.html">My account</a></li>
                                    <li><a href="checkout.html">Checkout</a></li>
                                    <li><a href="login.html">Sign in</a></li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown">
                                <button type="button" id="dropdownMenuButton-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">INR ₹ <i class="ion-ios-arrow-down"></i></button>

                                <ul class="dropdown-menu animation slideDownIn" aria-labelledby="dropdownMenuButton-2">
                                    <li><a href="#">INR ₹</a></li>
                                    <li><a href="#">USD $</a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="pr-0">
                            <div class="dropdown">
                                <button type="button" id="dropdownMenuButton-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="assets/images/flag/1.jpg" alt="" /> English <i class="ion-ios-arrow-down"></i>
                                </button>

                                <ul class="dropdown-menu animation slideDownIn" aria-labelledby="dropdownMenuButton-3">
                                    <li>
                                        <a href="#"><img src="assets/images/flag/1.jpg" alt="" /> English</a>
                                    </li>
                                    <li>
                                        <a href="#"><img src="assets/images/flag/2.jpg" alt="" /> Français</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Header Nav End -->
    <div class="header-top bg-white ptb-30px d-xl-block d-none">
        <div class="container">
            <div class="row">
                <div class="col-md-12" >
                    <div class="logo">
                        <a href="#"><img class="img-responsive " src="assets/images/logo/pnglogo.png" style="width: 183px; height: 51px;" alt="logo.jpg" /></a>
                    </div>
                </div>

                <!-- the search bar -->
                <!-- <div class="col-md-10 align-self-center">
                    <div class="header-right-element d-flex">
                        <div class="search-element media-body">
                            <form class="d-flex" action="#">
                                <div class="search-category">
                                    <select>
                                        <option value="0">All categories</option>
                                        <option value="12">Laptop</option>
                                        <option value="13">- - Hot Categories</option>
                                        <option value="19">- - - - Dresses</option>
                                        <option value="20">- - - - Jackets &amp; Coats</option>
                                        <option value="21">- - - - Sweaters</option>
                                        <option value="22">- - - - Jeans</option>
                                        <option value="23">- - - - Blouses &amp; Shirts</option>
                                        <option value="14">- - Outerwear &amp; Jackets</option>
                                        <option value="24">- - - - Basic Jackets</option>
                                        <option value="25">- - - - Real Fur</option>
                                        <option value="26">- - - - Down Coats</option>
                                        <option value="27">- - - - Blazers</option>
                                        <option value="28">- - - - Trench Coats</option>
                                        <option value="15">- - Weddings &amp; Events</option>
                                        <option value="29">- - - - Wedding Dresses</option>
                                        <option value="30">- - - - Evening Dresses</option>
                                        <option value="31">- - - - Prom Dresses</option>
                                        <option value="32">- - - - Bridesmaid Dresses</option>
                                        <option value="33">- - - - Wedding Accessories</option>
                                        <option value="16">- - Bottoms</option>
                                        <option value="34">- - - - Skirts</option>
                                        <option value="35">- - - - Leggings</option>
                                        <option value="36">- - - - Pants &amp; Capris</option>
                                        <option value="37">- - - - Wide Leg Pants</option>
                                        <option value="38">- - - - Shorts</option>
                                        <option value="17">- - Tops &amp; Sets</option>
                                        <option value="39">- - - - Tank Tops</option>
                                        <option value="40">- - - - Suits &amp; Sets</option>
                                        <option value="41">- - - - Jumpsuits</option>
                                        <option value="42">- - - - Rompers</option>
                                        <option value="43">- - - - Sleep &amp; Lounge</option>
                                        <option value="18">- - Accessories</option>
                                        <option value="44">- - - - Eyewear &amp; Accessories</option>
                                        <option value="45">- - - - Hats &amp; Caps</option>
                                        <option value="46">- - - - Belts &amp; Cummerbunds</option>
                                        <option value="47">- - - - Scarves &amp; Wraps</option>
                                        <option value="48">- - - - Gloves &amp; Mittens</option>
                                        <option value="49">Computer</option>
                                        <option value="50">- - Bottoms</option>
                                        <option value="53">- - - - Skirts</option>
                                        <option value="54">- - - - Leggings</option>
                                        <option value="55">- - - - Jeans</option>
                                        <option value="56">- - - - Pants &amp; Capris</option>
                                        <option value="57">- - - - Shorts</option>
                                        <option value="51">- - Outerwear &amp; Jackets</option>
                                        <option value="58">- - - - Trench</option>
                                        <option value="59">- - - - Genuine Leather</option>
                                        <option value="60">- - - - Parkas</option>
                                        <option value="61">- - - - Down Jackets</option>
                                        <option value="62">- - - - Wool &amp; Blends</option>
                                        <option value="52">- - Underwear &amp; Loungewear</option>
                                        <option value="63">- - - - Boxers</option>
                                        <option value="64">- - - - Briefs</option>
                                        <option value="65">- - - - Long Johns</option>
                                        <option value="66">- - - - Men's Sleep &amp; Lounge</option>
                                        <option value="67">- - - - Pajama Sets</option>
                                        <option value="68">Smartphone</option>
                                        <option value="69">- - Accessories &amp; Parts</option>
                                        <option value="75">- - - - Cables &amp; Adapters</option>
                                        <option value="76">- - - - Batteries</option>
                                        <option value="77">- - - - Chargers</option>
                                        <option value="78">- - - - Bags &amp; Cases</option>
                                        <option value="79">- - - - Electronic Cigarettes</option>
                                        <option value="70">- - Audio &amp; Video</option>
                                        <option value="80">- - - - Televisions</option>
                                        <option value="81">- - - - TV Receivers</option>
                                        <option value="82">- - - - Projectors</option>
                                        <option value="83">- - - - Audio Amplifier Boards</option>
                                        <option value="84">- - - - TV Sticks</option>
                                        <option value="71">- - Camera &amp; Photo</option>
                                        <option value="85">- - - - Digital Cameras</option>
                                        <option value="86">- - - - Camcorders</option>
                                        <option value="87">- - - - Camera Drones</option>
                                        <option value="88">- - - - Action Cameras</option>
                                        <option value="89">- - - - Photo Studio Supplies</option>
                                        <option value="72">- - Portable Audio &amp; Video</option>
                                        <option value="90">- - - - Headphones</option>
                                        <option value="91">- - - - Speakers</option>
                                        <option value="92">- - - - MP3 Players</option>
                                        <option value="93">- - - - VR/AR Devices</option>
                                        <option value="94">- - - - Microphones</option>
                                        <option value="73">- - Smart Electronics</option>
                                        <option value="95">- - - - Wearable Devices</option>
                                        <option value="96">- - - - Smart Home Appliances</option>
                                        <option value="97">- - - - Smart Remote Controls</option>
                                        <option value="98">- - - - Smart Watches</option>
                                        <option value="99">- - - - Smart Wristbands</option>
                                        <option value="74">- - Video Games</option>
                                        <option value="100">- - - - Handheld Game Players</option>
                                        <option value="101">- - - - Game Controllers</option>
                                        <option value="102">- - - - Joysticks</option>
                                        <option value="103">- - - - Stickers</option>
                                        <option value="104">Game Consoles</option>
                                        <option value="105">Mp3 &amp; headphone</option>
                                        <option value="106">Tv &amp; Video</option>
                                        <option value="107">Watches</option>
                                        <option value="108">Washing Machine</option>
                                        <option value="109">Camera</option>
                                        <option value="110">Audio &amp; Theater</option>
                                        <option value="111">Accessories</option>
                                        <option value="112">Games &amp; Consoles</option>
                                    </select>
                                </div>
                                <input type="text" placeholder="Enter your search key ... " />
                                <button><i class="icon-magnifier search-color"></i></button>
                            </form>
                        </div>
                        <div class="contact-link">
                            <div class="phone">
                                <p>Call us:</p>
                                <a href="tel:(07714026189)">0771-4026189</a>
                            </div>
                        </div>
                       
                        <div class="header-tools d-flex">
                            <div class="cart-info d-flex align-self-center">
                                <a href="compare.html" class="shuffle"><i class="icon-shuffle"></i></a>
                                <a href="#offcanvas-wishlist" class="heart offcanvas-toggle"><i class="icon-heart"></i></a>
                                <a href="#offcanvas-cart" class="bag offcanvas-toggle"><i class="icon-bag"></i><span>₹20.00</span></a>
                            </div>
                        </div>
                    </div>
                    
                </div> -->
            </div>
        </div>
    </div>
    <!-- Header Nav End -->
    <div class="header-menu bg-blue sticky-nav d-xl-block d-none padding-0px">
        <div class="container">
            <div class="row">
                <!-- the all categories menu -->
                
                <!-- <div class="col-lg-3 custom-col-2">
                    <div class="header-menu-vertical">
                        <h4 class="menu-title">All Categories</h4>
                        <ul class="menu-content display-none">
                            <li class="menu-item">
                                <a href="#">Electronics <i class="ion-ios-arrow-right"></i></a>
                                <ul class="sub-menu flex-wrap">
                                    <li>
                                        <a href="#">
                                            <span> <strong> Accessories & Parts</strong></span>
                                        </a>
                                        <ul class="submenu-item">
                                            <li><a href="#">Cables & Adapters</a></li>
                                            <li><a href="#">Batteries</a></li>
                                            <li><a href="#">Chargers</a></li>
                                            <li><a href="#">Bags & Cases</a></li>
                                            <li><a href="#">Electronic Cigarettes</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span><strong>Camera & Photo</strong></span>
                                        </a>
                                        <ul class="submenu-item">
                                            <li><a href="#">Digital Cameras</a></li>
                                            <li><a href="#">Camcorders</a></li>
                                            <li><a href="#">Camera Drones</a></li>
                                            <li><a href="#">Action Cameras</a></li>
                                            <li><a href="#">Photo Studio Supplie</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span><strong>Smart Electronics</strong></span>
                                        </a>
                                        <ul class="submenu-item">
                                            <li><a href="#">Wearable Devices</a></li>
                                            <li><a href="#">Smart Home Appliances</a></li>
                                            <li><a href="#">Smart Remote Controls</a></li>
                                            <li><a href="#">Smart Watches</a></li>
                                            <li><a href="#">Smart Wristbands</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span><strong>Audio & Video</strong></span>
                                        </a>
                                        <ul class="submenu-item">
                                            <li><a href="#">Televisions</a></li>
                                            <li><a href="#">TV Receivers</a></li>
                                            <li><a href="#">Projectors</a></li>
                                            <li><a href="#">Audio Amplifier Boards</a></li>
                                            <li><a href="#">TV Sticks</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span><strong>Portable Audio & Video</strong></span>
                                        </a>
                                        <ul class="submenu-item">
                                            <li><a href="#">Headphones</a></li>
                                            <li><a href="#">Speakers</a></li>
                                            <li><a href="#">MP3 Players</a></li>
                                            <li><a href="#">VR/AR Devices</a></li>
                                            <li><a href="#">Microphones</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <img src="assets/images/menu-image/banner-mega1.jpg" alt="" />
                                    </li>
                                </ul>
                                
                            </li>
                            <li class="menu-item">
                                <a href="#">Video Games <i class="ion-ios-arrow-right"></i></a>
                                <ul class="sub-menu sub-menu-2">
                                    <li>
                                        <ul class="submenu-item">
                                            <li><a href="#">Handheld Game Players</a></li>
                                            <li><a href="#">Game Controllers</a></li>
                                            <li><a href="#">Joysticks</a></li>
                                            <li><a href="#">Stickers</a></li>
                                        </ul>
                                    </li>
                                </ul>
                                
                            </li>
                            <li class="menu-item"><a href="#">Televisions</a></li>
                            <li class="menu-item"><a href="#">Digital Cameras</a></li>
                            <li class="menu-item"><a href="#">Headphones</a></li>
                            <li class="menu-item"><a href="#"> Wearable Devices</a></li>
                            <li class="menu-item"><a href="#"> Smart Watches</a></li>
                            <li class="menu-item"><a href="#"> Game Controllers</a></li>
                            <li class="menu-item"><a href="#"> Smart Home Appliances</a></li>
                        </ul>
                    
                    </div>
                
                </div> -->
               
                <div class="col-lg-9 custom-col-2">
                    <div class="header-horizontal-menu">
                        <ul class="menu-content">
                            <li class="active menu-dropdown">
                                <a href="home" >Home 
                                    <!-- <i class="ion-ios-arrow-down"></i> -->
                                </a>
                                <!-- home dropdown -->
                                <!-- <ul class="main-sub-menu">
                                    <li><a href="index.html">Home 1</a></li>
                                    <li><a href="index-2.html">Home 2</a></li>
                                    <li><a href="index-3.html">Home 3</a></li>
                                    <li><a href="index-4.html">Home 4</a></li>
                                </ul> -->
                            </li>
                            <li class="menu-dropdown">
                                <a href="e-waste-management" >E-Waste Management
                                    <!-- <i class="ion-ios-arrow-down"></i> -->
                                </a>
                                <!-- <ul class="mega-menu-wrap">
                                    <li>
                                        <ul>
                                            <li class="mega-menu-title"><a href="#">Shop Grid</a></li>
                                            <li><a href="shop-3-column.html">Shop Grid 3 Column</a></li>
                                            <li><a href="shop-4-column.html">Shop Grid 4 Column</a></li>
                                            <li><a href="shop-left-sidebar.html">Shop Grid Left Sidebar</a></li>
                                            <li><a href="shop-right-sidebar.html">Shop Grid Right Sidebar</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li class="mega-menu-title"><a href="#">Shop List</a></li>
                                            <li><a href="shop-list.html">Shop List</a></li>
                                            <li><a href="shop-list-left-sidebar.html">Shop List Left Sidebar</a></li>
                                            <li><a href="shop-list-right-sidebar.html">Shop List Right Sidebar</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li class="mega-menu-title"><a href="#">Shop Single</a></li>
                                            <li><a href="single-product.html">Shop Single</a></li>
                                            <li><a href="single-product-variable.html">Shop Variable</a></li>
                                            <li><a href="single-product-affiliate.html">Shop Affiliate</a></li>
                                            <li><a href="single-product-group.html">Shop Group</a></li>
                                            <li><a href="single-product-tabstyle-2.html">Shop Tab 2</a></li>
                                            <li><a href="single-product-tabstyle-3.html">Shop Tab 3</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li class="mega-menu-title"><a href="#">Shop Single</a></li>
                                            <li><a href="single-product-slider.html">Shop Slider</a></li>
                                            <li><a href="single-product-gallery-left.html">Shop Gallery Left</a></li>
                                            <li><a href="single-product-gallery-right.html">Shop Gallery Right</a></li>
                                            <li><a href="single-product-sticky-left.html">Shop Sticky Left</a></li>
                                            <li><a href="single-product-sticky-right.html">Shop Sticky Right</a></li>
                                        </ul>
                                    </li>
                                    <li class="w-100">
                                        <ul class="banner-megamenu-wrapper d-flex">
                                            <li class="banner-wrapper mr-30px">
                                                <a href="single-product.html"><img src="assets/images/menu-image/banner-menu2.jpg" alt="" /></a>
                                            </li>
                                            <li class="banner-wrapper">
                                                <a href="single-product.html"><img src="assets/images/menu-image/banner-menu3.jpg" alt="" /></a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul> -->
                            </li>
                            <li class="menu-dropdown">
                                <a href="about" >About
                                    <!-- <i class="ion-ios-arrow-down"></i> -->
                                </a>
                                <!-- <ul class="main-sub-menu">
                                    <li><a href="about.html">About Page</a></li>
                                    <li><a href="cart.html">Cart Page</a></li>
                                    <li><a href="checkout.html">Checkout Page</a></li>
                                    <li><a href="compare.html">Compare Page</a></li>
                                    <li><a href="login.html">Login & Register Page</a></li>
                                    <li><a href="my-account.html">Account Page</a></li>
                                    <li><a href="wishlist.html">Wishlist Page</a></li>
                                </ul> -->
                            </li>
                            <!-- <li class="menu-dropdown"> -->
                                <!-- <a href="#">Blog -->
                                     <!-- <i class="ion-ios-arrow-down"></i> -->
                                <!-- </a> -->
                                <!-- <ul class="main-sub-menu">
                                    <li class="menu-dropdown position-static">
                                        <a href="#">Blog Grid <i class="ion-ios-arrow-right"></i></a>
                                        <ul class="main-sub-menu main-sub-menu-2">
                                            <li><a href="blog-grid-left-sidebar.html">Blog Grid Left Sidebar</a></li>
                                            <li><a href="blog-grid-right-sidebar.html">Blog Grid Right Sidebar</a></li>
                                        </ul>
                                    </li>
                                    <li class="menu-dropdown position-static">
                                        <a href="#">Blog List <i class="ion-ios-arrow-right"></i></a>
                                        <ul class="main-sub-menu main-sub-menu-2">
                                            <li><a href="blog-list-left-sidebar.html">Blog List Left Sidebar</a></li>
                                            <li><a href="blog-list-right-sidebar.html">Blog List Right Sidebar</a></li>
                                        </ul>
                                    </li>
                                    <li class="menu-dropdown position-static">
                                        <a href="#">Blog Single <i class="ion-ios-arrow-right"></i></a>
                                        <ul class="main-sub-menu main-sub-menu-2">
                                            <li><a href="blog-single-left-sidebar.html">Blog Single Left Sidebar</a></li>
                                            <li><a href="blog-single-right-sidebar.html">Blog Single Right Sidbar</a></li>
                                        </ul>
                                    </li>
                                </ul> -->
                            <!-- </li> -->
                            <li><a href="contact" >contact Us</a></li>
                        </ul>
                    </div>
                    <!-- header horizontal menu -->
                    <!-- <div class="intro-text-shipping text-right">
                        <div class="free-ship">Free Shipping on Orders ₹50+</div>
                    </div> -->
                </div>
            </div>
            <!-- row -->
        </div>
        <!-- container -->
    </div>
    <!-- header menu -->
</header>
<!-- Header Section End Here -->    

<!-- Mobile Header Section Start -->
<div class="mobile-header d-xl-none sticky-nav bg-white ptb-10px">
<div class="container">
    <div class="row align-items-center">

        <!-- Header Logo Start -->
        <div class="col">
            <div class="header-logo">
                <a href="#"><img class="img-responsive" src="assets/images/logo/pnglogo.png" style="width: 183px; height: 51px;" alt="logo.jpg" /></a>
            </div>
        </div>
        <!-- Header Logo End -->

        <!-- Header Tools Start -->
        <div class="col-auto">
            <div class="header-tools justify-content-end">
                <!-- <div class="cart-info d-flex align-self-center">
                    <a href="compare.html" class="shuffle d-xs-none"><i class="icon-shuffle"></i></a>
                    <a href="#offcanvas-wishlist" class="heart offcanvas-toggle d-xs-none"><i class="icon-heart"></i></a>
                    <a href="#offcanvas-cart" class="bag offcanvas-toggle"><i class="icon-bag"></i><span>₹20.00</span></a>
                </div> -->
                <div class="mobile-menu-toggle">
                    <a href="#offcanvas-mobile-menu" class="offcanvas-toggle">
                        <svg viewBox="0 0 800 600">
                            <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                            <path d="M300,320 L540,320" id="middle"></path>
                            <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <!-- Header Tools End -->

    </div>
</div>
</div>
<!-- <div class="mobile-search-area d-xl-none mb-15px">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="search-element media-body">
                    <form class="d-flex" action="#">
                        <div class="search-category">
                            <select>
                                <option value="0">All categories</option>
                                <option value="12">Laptop</option>
                                <option value="13">- - Hot Categories</option>
                                <option value="19">- - - - Dresses</option>
                                <option value="20">- - - - Jackets &amp; Coats</option>
                                <option value="21">- - - - Sweaters</option>
                                <option value="22">- - - - Jeans</option>
                                <option value="23">- - - - Blouses &amp; Shirts</option>
                                <option value="14">- - Outerwear &amp; Jackets</option>
                                <option value="24">- - - - Basic Jackets</option>
                                <option value="25">- - - - Real Fur</option>
                                <option value="26">- - - - Down Coats</option>
                                <option value="27">- - - - Blazers</option>
                                <option value="28">- - - - Trench Coats</option>
                                <option value="15">- - Weddings &amp; Events</option>
                                <option value="29">- - - - Wedding Dresses</option>
                                <option value="30">- - - - Evening Dresses</option>
                                <option value="31">- - - - Prom Dresses</option>
                                <option value="32">- - - - Bridesmaid Dresses</option>
                                <option value="33">- - - - Wedding Accessories</option>
                                <option value="16">- - Bottoms</option>
                                <option value="34">- - - - Skirts</option>
                                <option value="35">- - - - Leggings</option>
                                <option value="36">- - - - Pants &amp; Capris</option>
                                <option value="37">- - - - Wide Leg Pants</option>
                                <option value="38">- - - - Shorts</option>
                                <option value="17">- - Tops &amp; Sets</option>
                                <option value="39">- - - - Tank Tops</option>
                                <option value="40">- - - - Suits &amp; Sets</option>
                                <option value="41">- - - - Jumpsuits</option>
                                <option value="42">- - - - Rompers</option>
                                <option value="43">- - - - Sleep &amp; Lounge</option>
                                <option value="18">- - Accessories</option>
                                <option value="44">- - - - Eyewear &amp; Accessories</option>
                                <option value="45">- - - - Hats &amp; Caps</option>
                                <option value="46">- - - - Belts &amp; Cummerbunds</option>
                                <option value="47">- - - - Scarves &amp; Wraps</option>
                                <option value="48">- - - - Gloves &amp; Mittens</option>
                                <option value="49">Computer</option>
                                <option value="50">- - Bottoms</option>
                                <option value="53">- - - - Skirts</option>
                                <option value="54">- - - - Leggings</option>
                                <option value="55">- - - - Jeans</option>
                                <option value="56">- - - - Pants &amp; Capris</option>
                                <option value="57">- - - - Shorts</option>
                                <option value="51">- - Outerwear &amp; Jackets</option>
                                <option value="58">- - - - Trench</option>
                                <option value="59">- - - - Genuine Leather</option>
                                <option value="60">- - - - Parkas</option>
                                <option value="61">- - - - Down Jackets</option>
                                <option value="62">- - - - Wool &amp; Blends</option>
                                <option value="52">- - Underwear &amp; Loungewear</option>
                                <option value="63">- - - - Boxers</option>
                                <option value="64">- - - - Briefs</option>
                                <option value="65">- - - - Long Johns</option>
                                <option value="66">- - - - Men's Sleep &amp; Lounge</option>
                                <option value="67">- - - - Pajama Sets</option>
                                <option value="68">Smartphone</option>
                                <option value="69">- - Accessories &amp; Parts</option>
                                <option value="75">- - - - Cables &amp; Adapters</option>
                                <option value="76">- - - - Batteries</option>
                                <option value="77">- - - - Chargers</option>
                                <option value="78">- - - - Bags &amp; Cases</option>
                                <option value="79">- - - - Electronic Cigarettes</option>
                                <option value="70">- - Audio &amp; Video</option>
                                <option value="80">- - - - Televisions</option>
                                <option value="81">- - - - TV Receivers</option>
                                <option value="82">- - - - Projectors</option>
                                <option value="83">- - - - Audio Amplifier Boards</option>
                                <option value="84">- - - - TV Sticks</option>
                                <option value="71">- - Camera &amp; Photo</option>
                                <option value="85">- - - - Digital Cameras</option>
                                <option value="86">- - - - Camcorders</option>
                                <option value="87">- - - - Camera Drones</option>
                                <option value="88">- - - - Action Cameras</option>
                                <option value="89">- - - - Photo Studio Supplies</option>
                                <option value="72">- - Portable Audio &amp; Video</option>
                                <option value="90">- - - - Headphones</option>
                                <option value="91">- - - - Speakers</option>
                                <option value="92">- - - - MP3 Players</option>
                                <option value="93">- - - - VR/AR Devices</option>
                                <option value="94">- - - - Microphones</option>
                                <option value="73">- - Smart Electronics</option>
                                <option value="95">- - - - Wearable Devices</option>
                                <option value="96">- - - - Smart Home Appliances</option>
                                <option value="97">- - - - Smart Remote Controls</option>
                                <option value="98">- - - - Smart Watches</option>
                                <option value="99">- - - - Smart Wristbands</option>
                                <option value="74">- - Video Games</option>
                                <option value="100">- - - - Handheld Game Players</option>
                                <option value="101">- - - - Game Controllers</option>
                                <option value="102">- - - - Joysticks</option>
                                <option value="103">- - - - Stickers</option>
                                <option value="104">Game Consoles</option>
                                <option value="105">Mp3 &amp; headphone</option>
                                <option value="106">Tv &amp; Video</option>
                                <option value="107">Watches</option>
                                <option value="108">Washing Machine</option>
                                <option value="109">Camera</option>
                                <option value="110">Audio &amp; Theater</option>
                                <option value="111">Accessories</option>
                                <option value="112">Games &amp; Consoles</option>
                            </select>
                        </div>
                        <input type="text" placeholder="Enter your search key ... " />
                        <button><i class="icon-magnifier search-color"></i></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Search Category End -->
<!-- <div class="mobile-category-nav d-xl-none mb-15px">
    <div class="container">
        <div class="row">
            <div class="col-md-12">

               
                <div class="hero-side-category">
                   
                    <div class="category-toggle-wrap">

                        <button class="category-toggle"><i class="fa fa-bars"></i> All Categories</button>
                    </div>

                
                    <nav class="category-menu">
                        <ul>
                            <li class="menu-item-has-children menu-item-has-children-1">
                                <a href="#">Accessories & Parts<i class="ion-ios-arrow-down"></i></a>
                              
                                <ul class="category-mega-menu category-mega-menu-1">
                                    <li><a href="#">Cables & Adapters</a></li>
                                    <li><a href="#">Batteries</a></li>
                                    <li><a href="#">Chargers</a></li>
                                    <li><a href="#">Bags & Cases</a></li>
                                    <li><a href="#">Electronic Cigarettes</a></li>
                                </ul>
                            </li>
                            <li class="menu-item-has-children menu-item-has-children-2">
                                <a href="#">Camera & Photo<i class="ion-ios-arrow-down"></i></a>
                             
                                <ul class="category-mega-menu category-mega-menu-2">
                                    <li><a href="#">Digital Cameras</a></li>
                                    <li><a href="#">Camcorders</a></li>
                                    <li><a href="#">Camera Drones</a></li>
                                    <li><a href="#">Action Cameras</a></li>
                                    <li><a href="#">Photo Studio Supplies</a></li>
                                </ul>
                            </li>
                            <li class="menu-item-has-children menu-item-has-children-3">
                                <a href="#">Smart Electronics <i class="ion-ios-arrow-down"></i></a>
                              
                                <ul class="category-mega-menu category-mega-menu-3">
                                    <li><a href="#">Wearable Devices</a></li>
                                    <li><a href="#">Smart Home Appliances</a></li>
                                    <li><a href="#">Smart Remote Controls</a></li>
                                    <li><a href="#">Smart Watches</a></li>
                                    <li><a href="#">Smart Wristbands</a></li>
                                </ul>
                            </li>
                            <li class="menu-item-has-children menu-item-has-children-4">
                                <a href="#">Audio & Video <i class="ion-ios-arrow-down"></i></a>
                              
                                <ul class="category-mega-menu category-mega-menu-4">
                                    <li><a href="#">Televisions</a></li>
                                    <li><a href="#">TV Receivers</a></li>
                                    <li><a href="#">Projectors</a></li>
                                    <li><a href="#">Audio Amplifier Boards</a></li>
                                    <li><a href="#">TV Sticks</a></li>
                                </ul>
                            </li>
                            <li class="menu-item-has-children menu-item-has-children-5">
                                <a href="#">Portable Audio & Video <i class="ion-ios-arrow-down"></i></a>
                               
                                <ul class="category-mega-menu category-mega-menu-5">
                                    <li><a href="#">Headphones</a></li>
                                    <li><a href="#">Speakers</a></li>
                                    <li><a href="#">MP3 Players</a></li>
                                    <li><a href="#">VR/AR Devices</a></li>
                                    <li><a href="#">Microphones</a></li>
                                </ul>
                            </li>
                            <li class="menu-item-has-children menu-item-has-children-6">
                                <a href="#">Video Game <i class="ion-ios-arrow-down"></i></a>
                               
                                <ul class="category-mega-menu category-mega-menu-6">
                                    <li><a href="#">Handheld Game Players</a></li>
                                    <li><a href="#">Game Controllers</a></li>
                                    <li><a href="#">Joysticks</a></li>
                                    <li><a href="#">Stickers</a></li>
                                </ul>
                            </li>
                            <li><a href="#">Televisions</a></li>
                            <li><a href="#">Digital Cameras</a></li>
                            <li><a href="#">Headphones</a></li>
                            <li><a href="#">Wearable Devices</a></li>
                            <li><a href="#">Smart Watches</a></li>
                            <li><a href="#">Game Controllers</a></li>
                            <li><a href="#"> Smart Home Appliances</a></li>
                            <li class="hidden"><a href="#">Projectors</a></li>
                            <li>
                                <a href="#" id="more-btn"><i class="ion-ios-plus-empty" aria-hidden="true"></i> More Categories</a>
                            </li>
                        </ul>
                    </nav>
                </div>

               
            </div>
        </div>
    </div>
</div> -->
<!-- Mobile Header Section End -->


<!-- Search Category Start -->

<!-- OffCanvas Wishlist Start -->
<!-- <div id="offcanvas-wishlist" class="offcanvas offcanvas-wishlist">
    <div class="inner">
        <div class="head">
            <span class="title">Wishlist</span>
            <button class="offcanvas-close">×</button>
        </div>
        <div class="body customScroll">
            <ul class="minicart-product-list">
                <li>
                    <a href="single-product.html" class="image"><img src="assets/images/product-image/1.jpg" alt="Cart product Image"></a>
                    <div class="content">
                        <a href="single-product.html" class="title">Walnut Cutting Board</a>
                        <span class="quantity-price">1 x <span class="amount">₹100.00</span></span>
                        <a href="#" class="remove">×</a>
                    </div>
                </li>
                <li>
                    <a href="single-product.html" class="image"><img src="assets/images/product-image/2.jpg" alt="Cart product Image"></a>
                    <div class="content">
                        <a href="single-product.html" class="title">Lucky Wooden Elephant</a>
                        <span class="quantity-price">1 x <span class="amount">₹35.00</span></span>
                        <a href="#" class="remove">×</a>
                    </div>
                </li>
                <li>
                    <a href="single-product.html" class="image"><img src="assets/images/product-image/3.jpg" alt="Cart product Image"></a>
                    <div class="content">
                        <a href="single-product.html" class="title">Fish Cut Out Set</a>
                        <span class="quantity-price">1 x <span class="amount">₹9.00</span></span>
                        <a href="#" class="remove">×</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="foot">
            <div class="buttons">
                <a href="wishlist.html" class="btn btn-dark btn-hover-primary mt-30px">view wishlist</a>
            </div>
        </div>
    </div>
</div> -->
<!-- OffCanvas Wishlist End -->

<!-- OffCanvas Cart Start -->

<!-- <div id="offcanvas-cart" class="offcanvas offcanvas-cart">
    <div class="inner">
        <div class="head">
            <span class="title">Cart</span>
            <button class="offcanvas-close">×</button>
        </div>
        <div class="body customScroll">
            <ul class="minicart-product-list">
                <li>
                    <a href="single-product.html" class="image"><img src="assets/images/product-image/1.jpg" alt="Cart product Image"></a>
                    <div class="content">
                        <a href="single-product.html" class="title">Walnut Cutting Board</a>
                        <span class="quantity-price">1 x <span class="amount">₹100.00</span></span>
                        <a href="#" class="remove">×</a>
                    </div>
                </li>
                <li>
                    <a href="single-product.html" class="image"><img src="assets/images/product-image/2.jpg" alt="Cart product Image"></a>
                    <div class="content">
                        <a href="single-product.html" class="title">Lucky Wooden Elephant</a>
                        <span class="quantity-price">1 x <span class="amount">₹35.00</span></span>
                        <a href="#" class="remove">×</a>
                    </div>
                </li>
                <li>
                    <a href="single-product.html" class="image"><img src="assets/images/product-image/3.jpg" alt="Cart product Image"></a>
                    <div class="content">
                        <a href="single-product.html" class="title">Fish Cut Out Set</a>
                        <span class="quantity-price">1 x <span class="amount">₹9.00</span></span>
                        <a href="#" class="remove">×</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="foot">
            <div class="sub-total">
                <strong>Subtotal :</strong>
                <span class="amount">₹144.00</span>
            </div>
            <div class="buttons">
                <a href="cart.html" class="btn btn-dark btn-hover-primary mb-30px">view cart</a>
                <a href="checkout.html" class="btn btn-outline-dark current-btn">checkout</a>
            </div>
            <p class="minicart-message">Free Shipping on All Orders Over ₹100!</p>
        </div>
    </div>
</div> -->
<!-- OffCanvas Cart End -->


<!-- OffCanvas Search Start -->
<div id="offcanvas-mobile-menu" class="offcanvas offcanvas-mobile-menu">
    <div class="inner customScroll">
        <div class="head">
            <span class="title">&nbsp;</span>
            <button class="offcanvas-close">×</button>
        </div>
        <!-- <div class="offcanvas-menu-search-form">
            <form action="#">
                <input type="text" placeholder="Search...">
                <button><i class="icon-magnifier"></i></button>
            </form>
        </div> -->
        <div class="offcanvas-menu">
            <ul>
                <li><a href="home" ><span class="menu-text">Home</span></a>
                    <!-- <ul class="sub-menu">
                        <li><a href="index.html"><span class="menu-text">Home 1</span></a></li>
                        <li><a href="index-2.html"><span class="menu-text">Home 2</span></a></li>
                        <li> <a href="index-3.html"><span class="menu-text">Home 3</span></a></li>
                        <li><a href="index-4.html"><span class="menu-text">Home 4</span></a></li>
                    </ul> -->
                </li>
                <li><a href="e-waste-management" ><span class="menu-text">E-Waste Management</span></a>
                    <!-- <ul class="sub-menu">
                        <li>
                            <a href="#"><span class="menu-text">Shop Grid</span></a>
                            <ul class="sub-menu">
                                <li><a href="shop-3-column.html">Shop Grid 3 Column</a></li>
                                <li><a href="shop-4-column.html">Shop Grid 4 Column</a></li>
                                <li><a href="shop-left-sidebar.html">Shop Grid Left Sidebar</a></li>
                                <li><a href="shop-right-sidebar.html">Shop Grid Right Sidebar</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#"><span class="menu-text">Shop List</span></a>
                            <ul class="sub-menu">
                                <li><a href="shop-list.html">Shop List</a></li>
                                <li><a href="shop-list-left-sidebar.html">Shop List Left Sidebar</a></li>
                                <li><a href="shop-list-right-sidebar.html">Shop List Right Sidebar</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#"><span class="menu-text">Shop Single</span></a>
                            <ul class="sub-menu">
                                <li><a href="single-product.html">Shop Single</a></li>
                                <li><a href="single-product-variable.html">Shop Variable</a></li>
                                <li><a href="single-product-affiliate.html">Shop Affiliate</a></li>
                                <li><a href="single-product-group.html">Shop Group</a></li>
                                <li><a href="single-product-tabstyle-2.html">Shop Tab 2</a></li>
                                <li><a href="single-product-tabstyle-3.html">Shop Tab 3</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#"><span class="menu-text">Shop Single</span></a>
                            <ul class="sub-menu">
                                <li><a href="single-product-slider.html">Shop Slider</a></li>
                                <li><a href="single-product-gallery-left.html">Shop Gallery Left</a></li>
                                <li><a href="single-product-gallery-right.html">Shop Gallery Right</a></li>
                                <li><a href="single-product-sticky-left.html">Shop Sticky Left</a></li>
                                <li><a href="single-product-sticky-right.html">Shop Sticky Right</a></li>
                            </ul>
                        </li>
                    </ul> -->
                </li>
                <!-- <li><a href="#"><span class="menu-text">Pages</span></a> -->
                    <!-- <ul class="sub-menu">
                        <li><a href="about.html">About Page</a></li>
                        <li><a href="cart.html">Cart Page</a></li>
                        <li><a href="checkout.html">Checkout Page</a></li>
                        <li><a href="compare.html">Compare Page</a></li>
                        <li><a href="login.html">Login & Register Page</a></li>
                        <li><a href="my-account.html">Account Page</a></li>
                        <li><a href="wishlist.html">Wishlist Page</a></li>
                    </ul> -->
                <!-- </li> -->
                <li><a href="about"><span class="menu-text">About</span></a>
                    <!-- <ul class="sub-menu">
                        <li><a href="#"><span class="menu-text">Blog Grid</span></a>
                            <ul class="sub-menu">
                                <li><a href="blog-grid-left-sidebar.html">Blog Grid Left Sidebar</a></li>
                                <li><a href="blog-grid-right-sidebar.html">Blog Grid Right Sidebar</a></li>
                            </ul>
                        </li>
                        <li><a href="#"><span class="menu-text">Blog List</span></a>
                            <ul class="sub-menu">
                                <li><a href="blog-list-left-sidebar.html">Blog List Left Sidebar</a></li>
                                <li><a href="blog-list-right-sidebar.html">Blog List Right Sidebar</a></li>
                            </ul>
                        </li>
                        <li><a href="#"><span class="menu-text">Blog Single</span></a>
                            <ul class="sub-menu">
                                <li><a href="blog-single-left-sidebar.html">Blog Single Left Sidebar</a></li>
                                <li><a href="blog-single-right-sidebar.html">Blog Single Right Sidbar</a></li>
                            </ul>
                        </li>
                    </ul> -->
                </li>
                <li><a href="contact" >Contact Us</a></li>
            </ul>
        </div>
        <!-- <div class="offcanvas-buttons mt-30px">
            <div class="header-tools d-flex">
                <div class="cart-info d-flex align-self-center">
                    <a href="my-account.html" class="user"><i class="icon-user"></i></a>
                    <a href="wishlist.html"><i class="icon-heart"></i></a>
                    <a href="cart.html"><i class="icon-bag"></i></a>
                </div>
            </div>
        </div> -->
        <!-- <div class="offcanvas-social mt-30px">
            <ul>
                <li>
                    <a href="#"><i class="icon-social-facebook"></i></a>
                </li>
                <li>
                    <a href="#"><i class="icon-social-twitter"></i></a>
                </li>
                <li>
                    <a href="#"><i class="icon-social-instagram"></i></a>
                </li>
                <li>
                    <a href="#"><i class="icon-social-google"></i></a>
                </li>
                <li>
                    <a href="#"><i class="icon-social-instagram"></i></a>
                </li>
            </ul>
        </div> -->
    </div>
</div>
<!-- OffCanvas Search End -->